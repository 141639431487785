export default {
  mounted(el) {
    const input = el.querySelector("input") || el.querySelector("textarea");
    if (input) {
      input.value = input.value.toLowerCase();
    }
  },
  updated(el) {
    const input = el.querySelector("input") || el.querySelector("textarea");
    if (input) {
      input.value = input.value.toLowerCase();
    }
  },
};
