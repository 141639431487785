<template>
  <EntityChange v-if="showEntityChange" class="ml-auto order-2" />
  <div class="flex order-3 md:order-last justify-end">
    <button
      id="menu-open"
      aria-label="Mobile Menu Toggle"
      class="block text-gray-600 md:hidden hover:text-gray-700 z-40"
      @click="toggleBurgerMenu()"
    >
      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12" />
        <line x1="3" y1="6" x2="21" y2="6" />
        <line x1="3" y1="18" x2="21" y2="18" />
      </svg>
    </button>
    <div
      id="menu"
      class="fixed inset-0 z-50 items-center justify-between hidden bg-white md:bg-transparent md:static md:flex"
      :style="OpenBurgerMenu && !isDesktop ? 'display:block;' : ''"
    >
      <div
        class="flex items-center justify-between h-12 px-4 py-2 border-b border-gray-100 md:hidden"
      >
        <span class="text-base font-semibold text-gray-600">
          {{ userName }}
        </span>
        <button
          id="menu-close"
          aria-label="Mobile Close"
          class="block h-6 w-6 flex items-center justify-center md:hidden"
          @click="toggleBurgerMenu()"
        >
          <span class="sr-only">Close</span>
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x md:hidden"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
      </div>
      <ul class="md:flex">
        <li
          v-if="studentDataAvailable || isParticipant"
          class="md:mx-6 md:hidden"
        >
          <router-link v-slot="{ href, navigate, isActive }" to="/applications">
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[
                isActive
                  ? [
                      primaryColor
                        ? 'brandingPrimaryBorder'
                        : 'border-university-primary',
                    ]
                  : '',
              ]"
              :href="href"
              @click="navigate"
            >
              <span>
                {{ isParticipant ? "My Applications" : "Student Applications" }}
              </span>
              <span
                :class="[
                  {
                    'absolute inset-x-0 bottom-0 hidden w-full h-1 rounded-sm md:block': isActive,
                  },
                  `${primaryColorClassBG}`,
                ]"
              ></span>
            </a>
          </router-link>
        </li>
        <li v-if="fareFinder" class="md:mx-6 md:hidden">
          <router-link
            v-slot="{ href, navigate, isActive }"
            :to="{ name: 'fare-finder' }"
          >
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[
                isActive
                  ? [
                      primaryColor
                        ? 'brandingPrimaryBorder'
                        : 'border-university-primary',
                    ]
                  : '',
              ]"
              :href="href"
              @click="navigate"
            >
              <span>
                Fare Finder
              </span>
              <span
                :class="[
                  {
                    'absolute inset-x-0 bottom-0 hidden w-full h-1 rounded-sm md:block': isActive,
                  },
                  `${primaryColorClassBG}`,
                ]"
              ></span>
            </a>
          </router-link>
        </li>

        <li
          v-if="
            (hasStudentManagementPermission || dynamicUniversity) &&
            !isImpersonating
          "
          class="md:mx-6 md:hidden"
        >
          <router-link v-slot="{ href, navigate, isActive }" to="/my-students">
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[
                isActive
                  ? [
                      primaryColor
                        ? 'brandingPrimaryBorder'
                        : 'border-university-primary',
                    ]
                  : '',
              ]"
              :href="href"
              @click="navigate"
            >
              <span>My Students</span>
              <span
                :class="[
                  {
                    'absolute inset-x-0 bottom-0 hidden w-full h-1 rounded-sm md:block': isActive,
                  },
                  `${primaryColorClassBG}`,
                ]"
              ></span>
            </a>
          </router-link>
        </li>
        <li v-if="studentView" class="md:mx-6 md:hidden">
          <router-link v-slot="{ href, navigate, isActive }" to="/students">
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[
                isActive
                  ? [
                      primaryColor
                        ? 'brandingPrimaryBorder'
                        : 'border-university-primary',
                    ]
                  : '',
              ]"
              :href="href"
              @click="navigate"
            >
              <span>Student View</span>
              <span
                :class="[
                  {
                    'absolute inset-x-0 bottom-0 hidden w-full h-1 rounded-sm md:block': isActive,
                  },
                  `${primaryColorClassBG}`,
                ]"
              ></span>
            </a>
          </router-link>
        </li>
        <li
          v-if="hasStudentManagementPermission"
          class="md:mx-6 md:hidden sm:block"
        >
          <router-link
            v-slot="{ href, navigate, isActive }"
            to="/notification-settings"
          >
            <a
              class="block font-normal border-transparent border-l-4 pl-8 text-base h-12 flex items-center relative text-gray-600 md:font-semibold md:pl-0 md:border-l-0 md:h-14 hover:text-gray-700 hover:no-underline"
              :class="[
                isActive
                  ? [
                      primaryColor
                        ? 'brandingPrimaryBorder'
                        : 'border-university-primary',
                    ]
                  : '',
              ]"
              :href="href"
              @click="navigate"
            >
              <span>Notification Settings</span>
              <span
                :class="[
                  {
                    'absolute inset-x-0 bottom-0 hidden w-full h-1 rounded-sm md:block': isActive,
                  },
                  `${primaryColorClassBG}`,
                ]"
              ></span>
            </a>
          </router-link>
        </li>
        <slot name="nav-mobile-list-items" />
        <li class="md:mx-6 md:hidden">
          <button
            class="block font-normal md:font-semibold border-transparent pl-8 md:pl-0 md:border-l-0 text-base h-12 md:h-14 flex items-center relative text-gray-600 hover:text-gray-700 hover:no-underline w-full"
            @click="logout"
          >
            {{ logoutName }}
          </button>
        </li>
        <li
          v-if="dynamicUniversity && !studentDataAvailable"
          class="md:mx-6 md:hidden sm:block px-8"
        >
          <UniversityChange class="w-90-p" :show-current="true" />
        </li>
      </ul>
      <!-- Profile avatar menu -->
      <ProfileMenu />
    </div>
  </div>
</template>

<script>
import { PERMISSIONS } from "../../constants";
import { mapGetters, mapState } from "vuex";
import UniversityChange from "@/components/Header/universityChange.vue";
import ProfileMenu from "./ProfileMenu.vue";
import EntityChange from "./EntityChange.vue";
import { isApolloError } from "@apollo/client/errors";
import store from "../../store";

export default {
  name: "NavBar",
  components: { UniversityChange, ProfileMenu, EntityChange },
  data() {
    return {
      OpenBurgerMenu: false,
      windowWidth: 0,
    };
  },
  computed: {
    ...mapState(["userData", "currentUser", "featureFlags"]),
    ...mapGetters({
      dynamicUniversity: "university/dynamicUniversity",
      permissions: "getPermissions",
      isImpersonating: "isImpersonating",
    }),
    fareFinder() {
      return (
        this.permissions.includes(PERMISSIONS.PARTICIPANTS) &&
        !this.permissions.includes(PERMISSIONS.INSTITUTION)
      );
    },
    isDesktop() {
      if (this.windowWidth > 900) {
        return true;
      } else {
        return false;
      }
    },
    studentDataAvailable() {
      return this.userData !== null && Object.entries(this.userData).length > 0;
    },
    studentView() {
      return (
        this.permissions.includes(PERMISSIONS.API_EMPLOYEE_STUDENT_VIEW) &&
        !this.isImpersonating
      );
    },
    hasStudentManagementPermission() {
      return (
        this.permissions.includes(PERMISSIONS.STUDENT_MANAGEMENT) &&
        !this.isImpersonating
      );
    },
    isParticipant() {
      return this.permissions.includes(PERMISSIONS.PARTICIPANTS);
    },
    userName() {
      return [
        this.isImpersonating ? "Student View:" : undefined,
        this.currentUser?.firstName,
        this.currentUser?.lastName,
      ]
        .filter((_) => _)
        .join(" ");
    },
    logoutName() {
      return this.isImpersonating ? "Student View" : "";
    },
    showEntityChange() {
      const path = this.$route.path;
      const isProgramManagerPath = !!path.match(
        /\/program-manager\/\w{3,25}(\/view-more)?$/g
      );
      const hasTenantLibraries = this.featureFlags["tenant-libraries"];
      const hasEntityChangePermissions =
        this.permissions.includes(PERMISSIONS.PRGMS_PUB) ||
        this.permissions.includes(PERMISSIONS.ITEMS_PUB);
      const isAPIEmployee = this.permissions.includes(PERMISSIONS.API_EMPLOYEE);
      if (isProgramManagerPath && (!hasEntityChangePermissions || !isAPIEmployee || !hasTenantLibraries)) {
        store.commit("programManager/setSelectedEntityView", {});
      };
      return (
        isProgramManagerPath &&
        hasTenantLibraries &&
        hasEntityChangePermissions &&
        isAPIEmployee
      );
    },
  },
  watch: {
    windowWidth: {
      handler: function (newVal) {
        if (newVal > 900) this.OpenBurgerMenu = false;
      },
      immediate: true,
    },
  },
  created() {
    const profileData = this.$store.state?.profileData;
    const oktaId = this.$store.state?.currentUser?.participantId ?? "";
    if (Object.keys(profileData).length === 0 && oktaId) {
      this.$store.dispatch("getProfile", oktaId);
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleBurgerMenu() {
      this.OpenBurgerMenu = !this.OpenBurgerMenu;
    },
    async logout() {
      if (this.isImpersonating) {
        await this.$store.dispatch("exitImpersonation");
      } else {
        await this.$store.dispatch("logout", {
          reload: true,
        });
      }
    },
  },
};
</script>
