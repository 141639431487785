import { cloneDeep, isEmpty } from "lodash";
import {checkForLegacyMapping, checkForProgramLengthLegacyMapping} from "./legacyUrlIdMappings";

const LEGACY_PARAM_SEPARATOR = "#$#";
const PARAM_SEPARATOR = ":=";
const LEGACY_PRICE_SEPARATOR = "|";

export const decodedParam = (decodedString) => {
  const parts = decodedString.split(LEGACY_PARAM_SEPARATOR);
  return parts;
};

export const getDecodedData = (decodedString, what = "value") => {
  const parts = decodedParam(decodedString);
  let position = what === "label" ? 0 : 1;
  return parts.length > 1 ? parts[position] : decodedString;
};

/**
 *
 * @param {*} initialKeys -> an array of keys where we will store each query param
 * it has to match the query key example:
 * if the query string look like this -> results?startDate=2023-06-05&endDate=2023-06-07
 * we need an array with the following:
 * initialKeys=[`startDate`,`endDate`]
 */
export const useQueryParamsToState = (route, initialKeys) => {
  if (!isEmpty(initialKeys)) {
    let decodedValue = []; //container for the decoded url param in case it is encoded
    const result = cloneDeep(initialKeys);

    Object.keys(route.query).forEach((urlKey) => {
      const queryValues = Array.isArray(route.query[urlKey])
        ? route.query[urlKey]
        : [route.query[urlKey]];

      queryValues.forEach((queryValue) => {
        const decodedQueryValue = decodeURIComponent(queryValue);
        if (urlKey === "keyword" || urlKey === "classTitle") {
          result[urlKey] = decodedQueryValue;
        } else if (urlKey === "startDate" || urlKey === "endDate") {
          if (decodedQueryValue) {
            let obj = {
              label:
                urlKey === "startDate"
                  ? "Start Date: " + decodedQueryValue
                  : "End Date: " + decodedQueryValue,
              value: decodedQueryValue,
            };
            result[urlKey].push(obj);
          }
        } else if (urlKey === "pastPrograms") {
          const pastPrograms = decodedQueryValue;
          result["pastPrograms"] = pastPrograms === "true";
        } else if (urlKey === "cities") {
          if (decodedQueryValue.includes(LEGACY_PARAM_SEPARATOR)) {
            const paramParts = decodedParam(decodedQueryValue);
            if (paramParts.length === 2) {
              const label = getDecodedData(decodedQueryValue, "label");
              result[urlKey].push(label.split(",")[0]);
            }
          } else {
            const label = decodedQueryValue.split(",")[0];
            if(label) {
              const convertedLegacyMapping = checkForLegacyMapping("cities", label)
              result[urlKey].push(convertedLegacyMapping);
            }
          }
        } else if (urlKey === "price") {
          decodedValue = getDecodedData(decodedQueryValue).split(LEGACY_PRICE_SEPARATOR);
          const filteredVal = decodedValue
            .map((element) => +element)
            .filter((val) => Number(val) >= 0);
          if (result["price"]?.length === 0) {
            if(decodedQueryValue.includes(LEGACY_PRICE_SEPARATOR)) { // If legacy price times 100
              result["price"] = filteredVal.map(val => {
                const converted = Number(val);
                if(val) {
                  return converted * 100;
                }
                return 0;
              });
            } else {
              result["price"] = filteredVal;
            }
          } else if (result["price"]?.length < 3) {
            result["price"] = [...result["price"], ...filteredVal];
          }
        } else if (urlKey === "session_id" || urlKey === "sessionId") {
          let parts = [];
          if (decodedQueryValue.includes(LEGACY_PARAM_SEPARATOR)) {
            parts = decodedParam(decodedQueryValue);
          } else {
            parts = decodedQueryValue?.split(PARAM_SEPARATOR);
          }
          result["sessionId"] = parts?.length === 2 ? parts : [];
        } else if (urlKey === "programLength") {
          const convertedLegacyMapping = checkForProgramLengthLegacyMapping(decodedQueryValue)
          if(convertedLegacyMapping?.length > 0) {
            result["timeframe"] = result["timeframe"].concat(convertedLegacyMapping)
          }
        } else {
          if (result[urlKey] && decodedQueryValue) {
            if (decodedQueryValue.includes(LEGACY_PARAM_SEPARATOR)) {
              const paramParts = decodedParam(decodedQueryValue);
              if (paramParts.length === 2) {
                result[urlKey].push(getDecodedData(decodedQueryValue, "label"));
              }
            } else {
              const convertedLegacyMapping = checkForLegacyMapping(urlKey, decodedQueryValue)
              result[urlKey].push(convertedLegacyMapping);
            }
          }
        }
      });
    });
    return result;
  }
}

export const useConvertToUrlParams = ( filters ) => {
  const newFilters = {
    ...filters,
    sessionId:
      filters?.["sessionId"]?.length == 2
        ? filters?.["sessionId"].join(PARAM_SEPARATOR)
        : "",
  };
  const nonEmptyFilters = Object.keys(newFilters).reduce((acc, val) => {
    if (
      newFilters[val] &&
      ((Array.isArray(newFilters[val]) && newFilters[val]?.length > 0) ||
        newFilters[val])
    ) {
      acc[val] = newFilters[val];
    }
    return acc;
  }, {});
  return nonEmptyFilters;
}
