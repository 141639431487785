export const algoliaConfigurations = {
  session: {
    activeSession: false,
    areaOfStudy: "session.subject_area_sublevels",
    career: false /* IGNORED */,
    cities: "session.session_travel_details.arrival_city.city_ascii",
    classActive: false,
    countries: "session.session_travel_details.arrival_city.country.name",
    courseLevel: "session.education_levels.value",
    courseSearch: false /* IGNORED */,
    hostInstitution: "session.session_hosting_institutions.entity.name",
    internshipStatus: false,
    keyword: false,
    language: "session.classes_language_of_instruction.value",
    pastPrograms: "session.application_deadline",
    price: "session.base_price_in_cents",
    // programLength: "session.terms.tags.name",
    // temporarily per JUAN-513
    programLength: false,
    programPageStatus: "program_page.status_id",
    programTypes: "session.session_types.name",
    searchByDate: false /* IGNORED */,
    sessionId: false,
    sessionStatusId: "session.status_id",
    timeframe: "session.terms.name",
  },
  internship: {
    activeSession: false,
    areaOfStudy: "internship.subject_area_sublevels",
    career: "internship.career_interests.soc_title",
    cities: "session.session_travel_details.arrival_city.city_ascii",
    classActive: false,
    countries: "session.session_travel_details.arrival_city.country.name",
    courseLevel: false /* IGNORED */,
    courseSearch: false /* IGNORED */,
    hostInstitution: false /* IGNORED */,
    internshipStatus: "internship.status",
    keyword: false,
    language: "internship.required_languages.value",
    pastPrograms: "session.application_deadline",
    price: "session.base_price_in_cents",
    programLength: false, //"internship.terms.tags.name",
    programPageStatus: "program_page.status_id",
    programTypes: "session.session_types.name",
    searchByDate: false /* IGNORED */,
    sessionId: false,
    sessionStatusId: "session.status_id",
    timeframe: "session.terms.name",
  },
  classes: {
    activeSession: "class.active_session",
    areaOfStudy: "class.subject_area_sublevels",
    career: false /* IGNORED */,
    cities: "class.host_institution.shipping_address_city",
    classActive: "class.status",
    countries: "class.host_institution.country.name",
    courseLevel: "class.level.value",
    courseSearch: false /* IGNORED */,
    hostInstitution: "class.host_institution.name",
    internshipStatus: false,
    keyword: false,
    language: "class.language_of_instruction.value",
    pastPrograms: false,
    price: false /* IGNORED */,
    programLength: false, //"class.terms.tags.name",
    programPageStatus: false,
    programTypes: false /* IGNORED */,
    searchByDate: false /* IGNORED */,
    sessionId: "class.sessions.session_id",
    sessionStatusId: false,
    timeframe: "class.terms.name",
  },
};
