export const HOUSING_FULFILLMENT_MODAL_OPTIONS = {
  empty: 0,
  ready_fulfillment: 1,
  fulfillment: 2,
  canceled: 3,
  independent_housing: 4,
};

export const ERROR_MESSAGE =
  "Sorry, we were not able to update the order at this time. Please try again later. If the error persists, please reach out to productsupport@apiexperience.com.";

export const ERROR_EMPTY_ROOM_ID_MESSAGE =
  "Sorry, we were not able to update the order at this time. Please try again later. Empty Room / Bed Id. ";

export const ERROR_OLD_ROOM_NOT_FOUND =
  "Sorry, we were not able to update the order at this time. The old Room / Bed Id was not found.";
export const ERROR_NEW_ROOM_NOT_FOUND =
  "Sorry, we were not able to update the order at this time. The new Room / Bed Id was not found.";
export const ERROR_ROOMS_NOT_FOUND =
  "Sorry, we were not able to update the order at this time. The old and new Room / Bed Id were not found.";

export const DEFAULT_HOUSING_ROOM_PRICE = {
  housingName: undefined,
  housingTier: undefined,
  roomType: undefined,
  roomPrice: undefined,
};
