<template>
  <div v-if="!loading" class="flex flex-col">
    <SimpleBreadCrumb
      class="md:mt-4 md:px-8"
      custom-active-class="font-semibold"
    />
    <div
      class="flex flex-col justify-start h-full bg-gray-100 md:block md:px-12"
    >
      <stepped-process
        :steps="steps"
        :current-step="currentStep"
        class="inline-block w-full h-full align-top bg-white md:w-9/12"
        @set-current-step="(slug) => setCurrentStep(slug)"
      />
      <div
        v-if="sessionId"
        class="block order-first px-6 py-4 h-full md:w-1/4 md:leading-6 md:inline-block md:sticky md:align-top md:top-0"
        :class="secondaryColor ? `bg-gray-275` : 'bg-teal-light'"
      >
        <order-details
          :current-order="currentOrder"
          :current-step="currentStep"
          :program-session="program.programSession"
          :ope8-id="ope8Id"
          :home-institution-id="homeInstitutionId"
          :hide-prices="hidePrices"
          @get-pricing="(_pricing) => (pricing = _pricing)"
        >
          <template #call-to-action>
            <!-- SAVE FOR LATER BUTTON -->
            <div v-if="showSaveButton" class="mt-9">
              <button
                class="flex justify-center items-center p-5 mt-auto w-full font-bold uppercase bg-white border-2"
                data-testid="save-for-later-button"
                :disabled="disabledSaveForLater"
                :class="[
                  disabledSaveForLater ? 'opacity-50 cursor-not-allowed' : '',
                  `${secondaryColorClassOutlinedNoHover}`,
                ]"
                @click="saveForLater"
              >
                <span class="inline-block text-base">
                  Save for later
                </span>
                <div class="inline-block py-1 ml-3">
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.38866 7.00874L-9.95546e-08 12.2882L1.89181 14L9 7.00874L1.89181 -1.01583e-06L-7.14641e-07 1.71183L5.38866 7.00874Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <p v-if="saveForLaterError" class="error text-error-900">
              {{ saveForLaterError }}
            </p>
          </template>
        </order-details>
      </div>
    </div>
  </div>
  <template v-else>
    <Spinner />
  </template>
  <ApplicationExistsModal
    :show-application-taken-modal="showApplicationTakenModal"
    :redirect="true"
    @close="showApplicationTakenModal = false"
  />
</template>

<script setup>
import _ from "lodash";
import {
  computed,
  onMounted,
  provide,
  reactive,
  readonly,
  ref,
  toRaw,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { validate as validateUUID } from "uuid";
import SteppedProcess from "@/components/SteppedProcess/SteppedProcess.vue";
import OrderDetails from "@/components/OrderDetails/OrderDetails.vue";
import ProgramStep from "./ProgramStep.vue";
import HousingStep from "./Housing/HousingStep.vue";
import ExcursionsStep from "./Excursion/ExcursionsStep.vue";
import CreditsStep from "./CreditsStep.vue";
import InternshipStep from "./Internship/InternshipStep.vue";
import CoursesStep from "./CoursesStep.vue";
import StepsCompleted from "./StepsCompleted.vue";
import enrollmentService from "@/services/enrollment.js";
import programSessions from "@/services/programSessions.js";
import { ONE_CREDIT_PRODUCTS, THREE_CREDIT_PRODUCTS } from "@/constants.js";
import ordersService from "@/services/orders.js";
import SimpleBreadCrumb from "@/components/shared/SimpleBreadCrumb.vue";
import internshipService from "@/services/internship";
import {
  CONGRESO_ID,
  requiredSchool as learnerTypeIdsThatRequireSchoolSelection,
  SALESFORCE_UMASS_ID,
  UMASS_ID,
  US_COLLEGE_STUDENT_ID,
} from "@/constants";
import miscProductsService from "@/services/miscProducts.js";
import eventsService from "@/services/events.js";
import productPriceService from "@/services/productPrices";
import { findProductPriceBySession } from "@/composables/productPrices.js";
import { applicationAlreadyExists } from "@/components/ExperiencePage/utils.js";
import ApplicationExistsModal from "@/components/ExperiencePage/ApplicationExistsModal.vue";
import pLimit from "p-limit";
import formService from "@/services/form";
import { useProgramSelectionData } from "@/composables/useProgramSelectionData.js";
import { useOrder } from "@/composables/useOrder";
import { useNotifications } from "@/composables/useNotifications";
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import { RoomPrice, ExcursionPrice } from "@/components/Configurator/pricing";
import Spinner from "@/components/helpers/Spinner.vue";
import * as Sentry from "@sentry/vue";
import applicationService from "@/services/application.js";
import { pastSessionDeadline } from "@/util/pastSessionDeadline";
import programsService from "@/services/programsService.js";
import entitiesService from "@/services/entities.js";
import { entityTypes } from "@/components/program-manager/sessions/constants.js";

const limit = pLimit(2);

const store = useStore();

const showApplicationTakenModal = ref(false);
const duplicateApplication = ref(false);
const pricing = ref(undefined);
const saveForLaterError = ref("");
const disabledSaveForLater = ref(false);

const loading = ref(false);
const router = useRouter();
const route = useRoute();
const orderApplicationId = ref("");
const orderStatus = ref("Draft");
const orderPaymentId = ref(null);

const programPageId = computed(() => route.params?.programPageId);
const submittedProgramSelectionFormId = ref(undefined);
const submittedOrderId = ref(null);
const programSelectionId = computed(() => {
  const application = store.state.studentApplications?.find(
    (app) => app.id === currentOrder.value.application_id
  );
  const programSelectionSubmission = application?.submissions?.find(
    (submission) => submission.formPath === "programselection"
  );
  return (
    programSelectionSubmission?._id ?? submittedProgramSelectionFormId.value
  );
});

// during order configuration, the URL query params are the source of truth for
// the current order; this lets us create links for arbitrary product preselections
// the following computed getters and setters encapsulate URL manipulation
// so that the rest of the app can treat these values as ordinary in-memory objects
const updateURLState = async (fieldName, value) => {
  // using router.replace here instead of .push prevents altering browser history
  await router.replace({
    query: {
      ...route.query,
      [fieldName]: value,
    },
  });
};

const sessionId = computed({
  get: () => {
    const session = route.query?.session;
    return validateUUID(session) ? session : undefined;
  },
  set: (id = "") => {
    updateURLState("session", id);
  },
});

const numberCredits = computed({
  get: () => {
    const value = Number(route.query?.credits);
    return isNaN(value) ? 0 : value;
  },
  set: (value = 0) => {
    updateURLState("credits", value);
  },
});

const makeURLArrayState = (fieldName) => {
  return computed({
    get: () => {
      const value = route.query?.[fieldName] ?? [];

      // making sure we are always operating on arrays, not scalars
      const selections = Array.isArray(value) ? value : [value];

      // validate UUIDs to prevent garbage entered into the URL from messing things up
      return selections.filter((selectedId) => validateUUID(selectedId));
    },
    set: (newSelections = []) => {
      updateURLState(fieldName, newSelections);
    },
  });
};
const roomIds = makeURLArrayState("rooms");
const eventOccurrenceIds = makeURLArrayState("event_occurrences");
const internshipIds = makeURLArrayState("internships");
const miscProductIds = makeURLArrayState("misc_products");

const fetchAndSetSelectedSchool = async () => {
  if (!homeInstitutionId.value) {
    program.school = undefined;
    return;
  }

  const schoolFromLoadedUniversities = program.loadedUniversities.find(
    (uni) => uni.id === homeInstitutionId.value
  );
  if (schoolFromLoadedUniversities) {
    program.school = schoolFromLoadedUniversities;
    return;
  }

  entitiesService
    .getEntityById(homeInstitutionId.value)
    .then(({ data }) => {
      program.school = data?.data?.items;
    })
    .catch(() => {
      program.school = undefined;
    });
};

const homeInstitutionId = computed({
  // TODO in getter, reject if not a valid id...  maybe?
  // Problem with that is  we'd have to round trip to Learning service
  get: () => {
    let homeInstitution = route.query?.homeInstitutionId;
    if (!homeInstitution) {
      homeInstitution = homeInstitutionIdFromProfile.value;
    }
    return homeInstitution;
  },
  set: (id = "") => {
    updateURLState("homeInstitutionId", id).then(fetchAndSetSelectedSchool);
  },
});

const defaultLearnerTypeId = computed(() => {
  // Sets "US College Student" by default OR the matching id from profile service
  return store.state?.profileData.learner_type_id ?? US_COLLEGE_STUDENT_ID;
});

const learnerTypeId = computed({
  get: () => {
    const id = route.query?.learnerType;
    return validateUUID(id) ? id : defaultLearnerTypeId.value;
  },
  set: (id = "") => {
    updateURLState("learnerType", id).then(() => {
      if (!learnerTypeIdsThatRequireSchoolSelection.includes(id)) {
        homeInstitutionId.value = undefined;
      }
    });
  },
});

const homeInstitutionIdFromProfile = computed(() => {
  if (!homeInstitutionId.value) {
    const affiliatedSchools = store.state?.profileData?.colleges ?? [];
    if (affiliatedSchools.length > 0) {
      return affiliatedSchools[0]?.college_id;
    }
  }

  return undefined;
});

const program = reactive({
  learnerType: undefined,
  school: undefined,
  programSession: undefined,
  loadedSessions: [],
  loadedUniversities: [],
  name: undefined,
  city: undefined,
  country: undefined,
  programPage: undefined,
  loading: false,
  universitiesLoading: false,
  sessionId,
});

const learnerTypes = computed(
  () => store.getters["learnerTypes/getLearnerTypes"]
);

/* watch learnerTypeId and update program.learnerType when it changes
 * this is needed b/c we need program.learnerType in vuex for the
 * account creation section after configurator
 */
watch(
  learnerTypeId,
  (id) => {
    program.learnerType = learnerTypes.value.find((type) => type.id === id);
  },
  { immediate: true }
);

const ope8Id = computed(() => {
  const ope8IdIntegerValue = program?.school?.ope_id;
  if (!ope8IdIntegerValue) {
    return undefined;
  }
  return String(ope8IdIntegerValue).padStart(8, "0");
});

const participantId = computed(
  () => store.state.currentUser?.participantId ?? ""
);

const housing = reactive({
  limit: 1, // max limit of rooms
  roomIds: readonly(roomIds),
  questionnaire: undefined,
  questionnaireId: undefined,
  roomPrices: {},
});

const excursions = reactive({
  eventInstanceIds: readonly(eventOccurrenceIds),
  explicitlyRefuseExcursions: false,
  preloaded: [],
  loading: true,
});

const internship = reactive({
  internshipIds: readonly(internshipIds.value),
  internships: [],
  maxItems: 3,
  requiredItems: 1,
  internshipItems: {
    items: [],
    count: 0,
  },
  loading: true,
});

const credits = reactive({
  option: "",
  cost: 0,
  miscId: "",
  prices: {},
  loading: false,
});

const configuratorOptions = reactive({
  isLoaded: false,
  rooms: [],
  internships: [],
  event_occurrences: [],
  addons: [],
  additional_fees: []
});

const setCreditsValue = (newProduct, oldProduct) => {
  // remove any previous selection from the list of add-ons
  let updatedMiscProductIds = miscProductIds.value.filter(
    (id) => id !== oldProduct?.productId
  );

  // update for new selection
  credits.cost = newProduct?.cost;
  credits.option = newProduct?.value;
  credits.miscId = newProduct?.productId;

  if (
    newProduct?.productId &&
    !updatedMiscProductIds.includes(newProduct.productId)
  ) {
    updatedMiscProductIds = [...updatedMiscProductIds, newProduct.productId];
  }

  // Set miscProductIds.value once
  miscProductIds.value = updatedMiscProductIds;
};

const courses = reactive({
  additionalCredit: false,
  courseIds: [],
});

const misc = reactive({
  explicitlyRefuseAddOns: false,
});

const sessionTravelDetails = computed(() => {
  return program?.programSession?.session_travel_details ?? [];
});
const arrivalDetails = computed(() => {
  const details = [...sessionTravelDetails.value];
  const sortedAsc = details.sort(
    (a, b) =>
      Number(new Date(a.arrival_date)) - Number(new Date(b.arrival_date))
  );
  return sortedAsc.length ? sortedAsc[0] : {};
});
const departureDetails = computed(() => {
  const details = [...sessionTravelDetails.value];
  const sortedDesc = details.sort(
    (a, b) =>
      Number(new Date(b.departure_date)) - Number(new Date(a.departure_date))
  );
  return sortedDesc.length ? sortedDesc[0] : {};
});

const additionalCreditFee = computed(() => {
  let fee = 0;
  if (courses.additionalCredit) {
    const oneCreditProduct = program.programSession?.additional_session_fees.find(
      (item) => item.product_id === ONE_CREDIT_PRODUCTS.id
    );
    const threeCreditProduct = program.programSession?.additional_session_fees.find(
      (item) => item.product_id === THREE_CREDIT_PRODUCTS.id
    );

    const oneCreditPrice = oneCreditProduct?.price_in_cents ?? 0;
    const threeCreditPrice = threeCreditProduct?.price_in_cents ?? 0;

    const finalCredits =
      numberCredits.value - program.programSession.academic_max_credits;
    const quotient = Math.floor(finalCredits / 3);
    const remainder = finalCredits % 3;

    fee = quotient * threeCreditPrice + remainder * oneCreditPrice;
  }
  return fee;
});

const preLoadInternshipCredits = async () => {
  credits.loading = true;
  const promises = [CONGRESO_ID, UMASS_ID].map((id) =>
    miscProductsService.getById(id)
  );
  return Promise.allSettled(promises)
    .then((promises) => {
      promises.forEach((fulfilledPromiseObject) => {
        const response = fulfilledPromiseObject?.value;
        const productId = response?.data?.data?.id ?? "";
        const productPrices = response?.data?.data?.product_prices ?? [];
        credits.prices[productId] = productPrices.reduce((acc, p) => {
          return acc + p.price_in_cents;
        }, 0);
      });
    })
    .finally(() => {
      credits.loading = false;
    });
};

const loadInternships = async ({
  program = program,
  city_ids,
  pagination = { itemsPerPage: 10, currentPage: 1 },
  q = "",
  terms = [],
  major = "",
  career_area = "",
  skip = 0,
}) => {
  internship.loading = true;
  let params = {
    limit: pagination.itemsPerPage,
    skip: skip,
    status: "Active",
    q: q,
    durations:
      program?.programSession?.internship_filter_criteria?.durations ?? [],
    terms: terms ?? [],
    city_ids: city_ids ?? [],
    major: major,
    career_area: career_area,
  };

  const response = await internshipService.getInternships(params);
  const data = response?.data?.data ?? {};
  internship.internshipItems = {
    items: data.items,
    count: data.count,
    end: data.end,
    start: data.start,
  };
  internship.loading = false;
};

const preLoadExcursions = async (session_events) => {
  let newOccurrenceIds = [];
  excursions.preloaded = [];

  if (session_events) {
    //Get occurrence prices
    let product_ids = session_events.map((item) => item.event_id);
    const productsResponse = await productPriceService.getList({
      product_ids,
    });

    const productsData = productsResponse?.data?.data?.items ?? [];
    const products = {};
    product_ids.forEach((id) => {
      const eventProducts = productsData.filter(
        (item) => item.product_id === id
      );

      const eventProduct = findProductPriceBySession(
        eventProducts,
        arrivalDetails.value.arrival_date
      );

      if (eventProduct) products[id] = eventProduct;
    });

    newOccurrenceIds = Object.keys(products);

    //Get and filter occurrence data
    const occurrenceResponse = await eventsService.getOccurrences({
      occurrence_ids: newOccurrenceIds,
    });
    let occurrences = occurrenceResponse.data?.data?.items?.filter(
      (item) => item
    );

    //Get and filter event data
    if (occurrences.length) {
      const eventRequests = occurrences.map((occurrence) =>
        limit(() =>
          eventsService.getOne(occurrence.event_id).catch(() => {
            return null;
          })
        )
      );
      let eventResponse = await Promise.all(eventRequests);
      let events = eventResponse
        .map((response) => response?.data?.data)
        .filter((item) => item)
        .reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
      return occurrences.map((occurrence) => ({
        ...occurrence,
        event: events[occurrence.event_id],
        price: products[occurrence.id].price_in_cents,
      }));
    }
  }
};

const contracts = ref([]);
watch(
  () => ope8Id.value,
  async (newOPEId, oldOPEId) => {
    if (newOPEId !== oldOPEId) {
      if (!newOPEId) {
        contracts.value = [];
      } else {
        const foundContracts = await enrollmentService.loadContracts(newOPEId);
        contracts.value = Object.assign([], foundContracts);
      }
    }
  },
  { immediate: true, deep: true }
);
provide("contracts", readonly(contracts));

const preLoadSessionProducts = async () => {
  if (route.params?.orderId && !route.params?.slug) {
    setOrderStep();
  }
  setCourseCredits();
  if (showInternship()) {
    await loadInternships({
      program: program,
      city_ids: citiesList.value,
      terms: termsList.value,
      durations:
        program?.programSession?.internship_filter_criteria?.durations ?? [],
    });
  }
  if (showExcursions()) {
    excursions.preloaded = await preLoadExcursions(
      program?.programSession?.session_events
    );
    excursions.loading = false;
  }
};

const loadProgramSessions = async () => {
  if (program.loadedSessions.length > 0) {
    return;
  }
  program.loading = true;
  // Load program page data
  try {
    const OPEN = 2;
    const programId = route.params.programPageId;
    const program_page_response = await programsService.getProgramById(
      programId,
      {
        session_status: [OPEN],
      }
    );
    if (typeof program_page_response?.data?.data?.items?.id === "string") {
      const returnedSessions =
        program_page_response?.data?.data?.items?.sessions ?? [];

      program.loadedSessions = returnedSessions.filter(
        (session) => !pastSessionDeadline(session)
      );
    }
    await loadProgramSession();
  } catch (e) {
    this.throwSentryError(e, "loadProgramSessions");
  } finally {
    program.loading = false;
  }
};

const loadProgramSession = async () => {
  if (sessionId.value) {
    const loadedSession = program.loadedSessions.find(
      (session) => session?.id === sessionId.value
    );
    if (loadedSession) {
      program.programSession = loadedSession;
      return preLoadSessionProducts();
    }
    return programSessions
      .getProgramSession({
        id: sessionId.value,
        rules: true,
        home_institution_id: homeInstitutionId.value,
      })
      .then(async (programSession) => {
        program.programSession =
          programSession?.data?.data?.items ?? undefined;
        return preLoadSessionProducts();
      });
  } else {
    program.programSession = undefined;
  }
};

watch(sessionId, loadProgramSession, { immediate: true });

const loadUniversityList = async () => {
  program.universitiesLoading = true;
  return entitiesService
    .getEntities({
      extraParams: {
        account_types: [entityTypes.home_institution],
        field: "name",
        order: "ASC",
      },
    })
    .then(({ data }) => {
      program.loadedUniversities = data?.data?.items ?? [];
      const institutionIdToFetch =
        homeInstitutionId.value ?? homeInstitutionIdFromProfile.value;
      if (institutionIdToFetch) {
        entitiesService.getEntityById(institutionIdToFetch).then((response) => {
          const selectedInstitution = response?.data?.data?.items;
          if (selectedInstitution) {
            program.loadedUniversities = program.loadedUniversities
              .filter((uni) => uni.id !== selectedInstitution.id)
              .concat([selectedInstitution]);
            homeInstitutionId.value = selectedInstitution.id;
          }
        });
      }
    })
    .catch(() => program.setLoadedUniversities([]))
    .finally(() => (program.universitiesLoading = false));
};

provide("program", {
  program: readonly(program),
  arrivalDetails: readonly(arrivalDetails),
  departureDetails: readonly(departureDetails),
  setLoadedUniversities: (universities = []) => {
    program.loadedUniversities = universities;
  },
  homeInstitutionId,
  learnerTypeId,
  setProgramSession: async (programSession) => {
    let exists = false;
    if (store.state.currentUser && !currentOrder.value.orderId) {
      const { studentApplications } = store.state;
      exists = await applicationAlreadyExists({
        programSession: programSession,
        studentApplications: studentApplications,
        skipRequest: true,
      });
      showApplicationTakenModal.value = exists;
      duplicateApplication.value = exists;
    }
    // changing program session invalidates other selections
    if (programSession?.id !== program.programSession?.id && !exists) {
      housing.questionnaire = undefined;
      courses.additionalCredit = false;
      courses.courseIds = [];
      credits.option = "";
      credits.cost = 0;
      credits.miscId = "";
      await router.replace({
        query: {
          session: programSession?.id,
          room_ids: [],
          event_occurrence_ids: [],
          internship_ids: [],
          credits: 0,
          misc_product_ids: [],
        },
      });
    }
  },
});

provide("housing", {
  housing: readonly(housing),
  setRoomIds: (ids) => (roomIds.value = ids),
  setQuestionnaire: (questionnaire) => (housing.questionnaire = questionnaire),
});

const excursionRules = computed(() => {
  const populatedRules = {
    andIds: [],
    orIds: [],
    defaultIds: [],
  };

  const rules = program?.programSession?.session_event_selection_rules ?? [];
  for (const rule of rules) {
    const ids = rule?.library_events?.map((item) => item?.event_id);

    if (rule?.conjunction === "or" && rule?.default_event_id)
      populatedRules.defaultIds.push(rule?.default_event_id);

    if (rule?.conjunction === "and")
      populatedRules.andIds = [...populatedRules.andIds, ...ids];

    if (rule?.conjunction === "or")
      populatedRules.orIds = [...populatedRules.orIds, ...ids];
  }

  return populatedRules;
});

provide("excursionRules", readonly(excursionRules));

provide("excursions", {
  excursions: readonly(excursions),
  addRegistration: (occurrenceId) => {
    excursions.explicitlyRefuseExcursions = false;
    if (!eventOccurrenceIds.value.includes(occurrenceId)) {
      eventOccurrenceIds.value = eventOccurrenceIds.value.concat([
        occurrenceId,
      ]);
    }
  },
  removeRegistration: (occurrenceId) => {
    eventOccurrenceIds.value = eventOccurrenceIds.value.filter(
      (id) => id !== occurrenceId
    );
  },
  addMultipleRegistrations: (newOccurrenceIds) => {
    eventOccurrenceIds.value = [
      ...eventOccurrenceIds.value,
      ...newOccurrenceIds.filter(
        (id) => !eventOccurrenceIds.value.includes(id)
      ),
    ];
  },
  refuseExcursions: () => (excursions.explicitlyRefuseExcursions = true),
  getExcursions: (sessions_events) => preLoadExcursions(sessions_events),
});

const getInternships = computed(() => {
  return internship.internshipItems.items.filter((item) =>
    internshipIds.value.includes(item.id)
  );
});
provide("credits", {
  credits: readonly(credits),
  internships: getInternships,
  miscProductIds,
  setCreditsValue,
});

provide("internship", {
  internship: readonly(internship),
  selectedInternshipIds: internshipIds,
  addInternship: (id) => {
    if (!internshipIds.value.includes(id)) {
      internshipIds.value = [...internshipIds.value, id];
    }
  },
  removeInternship: (id) => {
    internshipIds.value = internshipIds.value.filter((_id) => id !== _id);
  },
  loadInternships,
});

provide("courses", {
  courses: readonly(courses),
  selectedCredit: readonly(numberCredits),
  additionalCreditFee: readonly(additionalCreditFee),
  setCredits: (value) => (numberCredits.value = value),
  setAdditionalCredit: (value) => (courses.additionalCredit = value),
});

provide("misc", {
  misc: readonly(misc),
  includeAddOn: (addon) => {
    misc.explicitlyRefuseAddOns = false;
    miscProductIds.value = [...miscProductIds.value, addon];
  },
  refuseAddOns: () => (misc.explicitlyRefuseAddOns = true),
});

const programStepIsComplete = () => {
  return (
    (program.programSession?.id &&
      sessionId.value &&
      learnerTypeId.value &&
      homeInstitutionIsSelectedOrNotRequired()) ||
    Boolean(route?.params?.orderId)
  );
};

const homeInstitutionIsSelectedOrNotRequired = () => {
  if (learnerTypeIdsThatRequireSchoolSelection.includes(learnerTypeId.value)) {
    return Boolean(homeInstitutionId.value);
  }
  return true;
};

const creditsStepIsComplete = () => {
  const creditProductIds = new Set([UMASS_ID, CONGRESO_ID]);
  return (
    internshipStepIsComplete() &&
    (Boolean(credits.option) ||
      new Set(miscProductIds.value).intersection(creditProductIds).size > 0 ||
      Boolean(route.params?.orderId))
  );
};

const housingStepIsComplete = () =>
  Boolean(roomIds.value.length > 0) || !program?.programSession?.default_room?.id;

const internshipStepIsComplete = () =>
  Boolean(internshipIds.value.length >= internship.requiredItems);

const coursesStepIsComplete = () => Boolean(numberCredits.value > 0);

const showCredits = () =>
  showInternship() && homeInstitutionId.value !== SALESFORCE_UMASS_ID;
const showCourses = () =>
  !_.isEmpty(program?.programSession?.class_filter_criteria);
const showHousing = () => !_.isEmpty(program?.programSession?.session_units);
const showExcursions = () =>
  !_.isEmpty(program?.programSession?.session_events) ||
  !_.isEmpty(program?.programSession?.session_event_selection_rules);

const showInternship = () =>
  !_.isEmpty(program?.programSession?.internship_filter_criteria?.durations) ||
  !_.isEmpty(program?.programSession?.internship_filter_criteria?.cities) ||
  !_.isEmpty(program?.programSession?.internship_filter_criteria?.terms);

const steps = computed(() => {
  const availableSteps = [
    {
      slug: "program",
      title: "Program",
      showStep: true,
      isSelectable: () => true,
      isComplete: programStepIsComplete,
      component: ProgramStep,
    },
    {
      slug: "loader",
      title: "loading",
      showStep: _.isEmpty(program?.programSession),
      isSelectable: () => false,
      isComplete: () => false,
    },
    {
      slug: "loader2",
      title: "loading",
      showStep: _.isEmpty(program?.programSession),
      isSelectable: () => false,
      isComplete: () => false,
    },
    {
      slug: "courses",
      title: "Courses",
      showStep: showCourses(),
      isSelectable: programStepIsComplete,
      isComplete: coursesStepIsComplete,
      component: CoursesStep,
    },
    {
      slug: "internship",
      title: "Internship",
      showStep: showInternship(),
      isSelectable: programStepIsComplete,
      isComplete: internshipStepIsComplete,
      component: InternshipStep,
    },
    {
      slug: "credits",
      title: "Credits",
      showStep: showCredits(),
      isSelectable: internshipStepIsComplete,
      isComplete: creditsStepIsComplete,
      component: CreditsStep,
    },
    {
      slug: "housing",
      title: "Housing",
      showStep: showHousing(),
      isSelectable: programStepIsComplete,
      isComplete: housingStepIsComplete,
      component: HousingStep,
    },
    {
      slug: "excursions",
      title: "Excursions",
      showStep: showExcursions(),
      isSelectable: programStepIsComplete,
      isComplete: () => eventOccurrenceIds.value.length > 0,
      component: ExcursionsStep,
    },
  ];

  return availableSteps.filter(
    (step) =>
      step.showStep || (route.params.orderId && route.params.slug === step.slug)
  );
});

const currentStep = computed(() => {
  const slug = route.params?.slug;
  if (!slug) {
    return steps.value[0];
  }
  if (slug === "steps-complete") {
    return {
      slug: "steps-complete",
      isSelectable: () => true,
      component: StepsCompleted,
    };
  } else {
    return steps.value.find((step) => step.slug === slug) || steps.value[0];
  }
});

const setCurrentStep = (slug) => {
  const newStepIndex = steps.value.findIndex((step) => step.slug === slug);
  const newStep = steps.value[newStepIndex];
  if (newStep.isSelectable()) {
    const to = {
      params: { ...route.params, slug: newStep.slug },
      query: route.query,
    };
    router.push(to);
    return;
  }
  // if desired step is not yet selectable, go to the first
  // selectable step in the sequence
  setCurrentStep(steps.value[newStepIndex - 1].slug);
};

const firstIncompleteStep = computed(() => {
  return steps.value.find((step) => !step.isComplete());
});

const goToNextStep = () => {
  const index = steps.value.findIndex(
    (step) => step.slug === currentStep.value.slug
  );
  if (index === steps.value.length - 1) {
    //The returned index is the last element in the steps
    goToFinalPage();
  } else {
    //The returned index is not the last element in the steps
    const nextStep = steps.value[index + 1] ?? currentStep.value;
    if (nextStep.isSelectable()) {
      setCurrentStep(nextStep.slug);
    } else {
      setCurrentStep(firstIncompleteStep.value.slug);
    }
  }
};

const goToPreviousStep = () => {
  const index = steps.value.findIndex(
    (step) => step.slug === currentStep.value.slug
  );
  const previousStep = index > 0 ? steps.value[index - 1] : currentStep.value;
  if (previousStep.isSelectable()) {
    setCurrentStep(previousStep.slug);
  }
};

const allStepsCompleted = computed(() =>
  steps.value.every((step) => Boolean(step?.isComplete()))
);

const goToFinalPage = () => {
  if (allStepsCompleted.value) {
    router.push({
      params: { ...route.params, slug: "steps-complete" },
      query: route.query,
    });
  } else {
    const firstIncomplete =
      steps.value.find((step) => !step.isComplete()) ?? steps.value[0];
    setCurrentStep(firstIncomplete.slug);
  }
};

provide("steps", {
  steps: readonly(steps),
  currentStep: readonly(currentStep),
  setCurrentStep,
  goToNextStep,
  goToPreviousStep,
});

const hidePrices = computed(
  () => currentStep.value.slug === steps.value[0]?.slug
);

const currentOrder = computed(() => {
  return {
    program_name: program?.programPage?.name ?? program.programSession?.name,
    orderId: route.params?.orderId,
    program_page_id: programPageId.value,
    session_id: sessionId.value,
    room_ids: roomIds.value,
    event_occurrence_ids: eventOccurrenceIds.value,
    internship_ids: internshipIds.value,
    credits: numberCredits.value,
    misc_product_ids: miscProductIds.value,
    ope_id: ope8Id.value,
    home_institution_id: homeInstitutionId.value,
    application_id: orderApplicationId.value,
    status: orderStatus.value,
    application_fee_payment_id: orderPaymentId.value,
  };
});

const setCourseCredits = () => {
  if (
    program.programSession?.academic_min_credits ===
      program.programSession?.academic_max_credits &&
    !program.programSession?.academic_additional_courses_allowed
  ) {
    numberCredits.value = program.programSession?.academic_min_credits ?? 0;
  }
};

const termsList = computed(() => {
  return (
    program?.programSession?.internship_filter_criteria?.terms?.map(
      (term) => term.id
    ) ?? []
  );
});
const citiesList = computed(() => {
  return (
    program?.programSession?.internship_filter_criteria?.cities?.map(
      (city) => city.city_id
    ) ?? []
  );
});

watch(homeInstitutionId, (newValue, oldValue) => {
  if (_.isEqual(newValue, oldValue)) {
    return;
  }
  loadConfiguratorOptions();
});

const loadConfiguratorOptions = async () => {
  if (!program?.programSession) {
    return;
  }
  if (homeInstitutionId.value?.length > 0) {
    const events = program?.programSession?.session_events ?? [];
    await ordersService.getOrderOptionsPricing({
        payload: {
          session_id: program?.programSession?.id,
          home_institution_id: homeInstitutionId.value,
          rooms: [],
          internships: [],
          event_occurrences: events.map((e) => e.event_id),
          addons: [],
          additional_fees: [],
        },
      })
    .then((response) => {
          if (response.data) {
            mapOrderPricingResponse(response.data);
          }
    }, (error) => console.error(`An error occurred: ${error.message}`));
  }
}

const mapOrderPricingResponse = (orderResponse) => {
  // Rooms
  configuratorOptions.rooms = orderResponse.rooms?.map((room) => {return {...RoomPrice}.optionMapper(room);}) ?? [];
  configuratorOptions.rooms = configuratorOptions.rooms.sort((l, r) => l.isCheaper(r))
  housing.roomPrices = Object.fromEntries(
    configuratorOptions.rooms.map(room => [
      room.productId,
      room.studentTotalPriceInCents,
    ])
  );
  // Apply home institution pricing to session rooms if available
  // Used for sorting
  const sessionUnits = program?.programSession?.session_units ?? []
  if (sessionUnits.length > 0) {
    const unitRooms = sessionUnits.map(su => su.unit.rooms).flat()
    configuratorOptions.rooms.forEach((roomItem) => {
      const rm = unitRooms.find(r => r.id === roomItem.productId);
      rm['student_total_price_in_cents'] = roomItem.studentTotalPriceInCents;
    });
  }

  configuratorOptions.internships = orderResponse.internships ?? [];
  // Excursions
  configuratorOptions.event_occurrences = orderResponse.event_occurrences?.map((excursion) => {
    return {...ExcursionPrice}.optionMapper(excursion)
    }) ?? [];
  configuratorOptions.event_occurrences = configuratorOptions.event_occurrences.sort((l, r) => l.isCheaper(r))

  configuratorOptions.addons = orderResponse.addons ?? [];
  configuratorOptions.additional_fees = orderResponse.additional_fees ?? [];
  configuratorOptions.isLoaded = true;
};

provide('configuratorOptions', {
  configuratorOptions: readonly(configuratorOptions),
  getSelectedRooms: () => configuratorOptions.rooms.filter((room) => roomIds.value.find((id) => id === room.productId)),
});

watch(
  currentStep,
  () => {
    if (!currentStep.value?.isSelectable()) {
      setCurrentStep(
        (steps.value.find((step) => step?.isSelectable()) ?? steps.value[0])
          ?.slug
      );
    }
  },
  { immediate: true }
);

const { createSubmissionDataForFormIo } = useProgramSelectionData();
const { prepOrderData } = useOrder();
const { sendNotifications } = useNotifications();
const {
  createOrUpdatedHousingQuestionnaire,
  getHousingQuestionnaire,
} = useHousingQuestionnaire();

const oktaId = computed(() => {
  return store.state.currentUser?.participantId ?? "";
});
const profileData = computed(() => {
  return store.state.profileData;
});
const showSaveButton = computed(() => {
  const slugsToHide = [steps.value[0]?.slug, "steps-complete"];

  return (
    !!program.programSession?.id &&
    !slugsToHide.includes(currentStep.value.slug)
  );
});

const submitDraftApplication = async () => {
  let formioData = null;
  saveForLaterError.value = "";

  try {
    formioData = createSubmissionDataForFormIo(
      program.programSession,
      orderApplicationId.value
    );

    const applicationId = formioData?.data?.application_id;

    // Save/Update draft order
    let orderResponse;
    const orderData = await prepOrderData(
      applicationId,
      program.programSession,
      currentOrder.value
    );

    if (currentOrder.value.orderId || submittedOrderId.value) {
      orderResponse = await ordersService.updateOrder({
        order_id: currentOrder.value.orderId || submittedOrderId.value,
        payload: orderData,
        session: program?.programSession,
        callingLocation: "Configurator",
      });
    } else {
      orderResponse = await ordersService.createOrder({
        payload: orderData,
      });
    }

    const orderID = orderResponse?.data?.data?.order?.id ?? "";

    if (!orderID) throw "Missing order id for application creation";
    submittedOrderId.value = orderID;

    // Submit formio program selection form
    const formResponse = await formService.createOrUpdateSubmission(
      "programselection",
      formioData,
      programSelectionId.value
    );

    submittedProgramSelectionFormId.value = formResponse._id;

    if (!currentOrder.value.orderId && formResponse) {
      sendNotifications("start", formResponse.data.programSession);
    }

    const applicationData = {
      application_id: applicationId,
      order_id: orderID,
      program_id: currentOrder.value.program_page_id,
      configurator_completed: false,
    };

    // Save/Update application info on connect db
    try {
      if (currentOrder.value.orderId) {
        await applicationService.updateV3Application(
          applicationId,
          applicationData
        );
      } else {
        await applicationService.createV3Application(applicationData);
      }
    } catch (error) {
      Sentry.captureException(
        new Error("Save in configurator error (Connect Application)."),
        {
          tags: {
            error: error.message,
            order_id: orderID,
          },
        }
      );
    }

    try {
      await createOrUpdatedHousingQuestionnaire(
        participantId.value,
        orderID,
        applicationId,
        housing.questionnaire,
        housing.questionnaireId
      );
    } catch (error) {
      Sentry.captureException(
        new Error("Save in configurator error (Housing Questionnaire)."),
        {
          tags: {
            error: error.message,
            order_id: orderID,
          },
        }
      );
    }

    await router.push({ name: "applications" });
  } catch (error) {
    loading.value = false;
    disabledSaveForLater.value = false;
    saveForLaterError.value =
      "Please try again. If this problem persists, please contact productsupport@apiexperience.com.";
    Sentry.captureException(new Error("Save in configurator error."), {
      tags: {
        error: error.message,
      },
    });
  }
};

const redirectToCreateAccount = () => {
  //save current configurator order to vuex module
  store.commit("setNewAccountAndApplication", true);
  store.commit("setProgramSessionDataV3", program.programSession);

  store.dispatch("configurator/setCurrentOrder", currentOrder.value);
  store.dispatch("configurator/setCurrentProgram", toRaw(program));
  store.dispatch("configurator/setConfiguratorIncomplete", true);
  store.dispatch("configurator/setHousingQuestionnaire", housing.questionnaire);
  store.dispatch("configurator/setSaveForLater", true);

  // Save session id to check if the application is duplicate
  // after login
  if (program?.programSession?.salesforce_id) {
    localStorage.setItem(
      "crossCheckingProgramSession",
      JSON.stringify(program.programSession.salesforce_id)
    );
  }

  /**
   * We're not using vue navigation because we have a logic on
   * the laravel web routes to decided which form we want to load
   * base on a feature flag.
   */
  window.location.href = "/create-account";
};

const saveForLater = () => {
  disabledSaveForLater.value = true;
  loading.value = true;
  if (store?.getters?.isLoggedIn) {
    submitDraftApplication();
  } else {
    redirectToCreateAccount();
  }
};

const loadStudentType = () => {
  const studentType = store.state.userData?.data?.student_type;
  const studentTypeId = profileData.value?.learner_type_id;
  const university = store.state.currentUser.value?.university;

  if (studentType && studentTypeId)
    program.learnerType = { value: studentType, id: studentTypeId };

  if (university) {
    program.school = {
      name: university.label,
      value: university.value,
      ope_id: university.value,
    };
  }
};

const loadHousingQuestionnaire = async () => {
  const questionnaireResponse = await getHousingQuestionnaire(
    currentOrder.value.orderId
  );
  housing.questionnaire = questionnaireResponse?.submission_data;
  housing.questionnaireId = questionnaireResponse?.id;
};

const loadOrder = async () => {
  const id = route.params?.orderId;

  if (!id) {
    if (!currentStep.value.isSelectable()) {
      setCurrentStep("program");
    }
    return;
  }

  loadStudentType();

  let promises = [loadHousingQuestionnaire()];

  promises.push(
    ordersService.getOne({ order_id: id }).then((orderResponse) => {
      const orderData = orderResponse?.data?.data?.order;
      orderApplicationId.value = orderData?.application_id;
      orderStatus.value = orderData?.status ?? orderStatus.value;
      orderPaymentId.value = orderData?.application_fee_payment_id ?? null;

      const orderQuery = {
        rooms: orderData?.room_ids ?? [],
        event_occurrences: orderData?.event_occurrence_ids ?? [],
        internships: orderData?.internship_ids ?? [],
        credits: orderData?.credits ?? 0,
        misc_products: orderData?.misc_product_ids ?? [],
        session: orderData?.session_id,
        homeInstitutionId: orderData?.home_institution_id,
      };

      router.replace({
        query: {
          ...route.query,
          ...orderQuery,
        },
      });
    })
  );

  return Promise.all(promises);
};

const setOrderStep = () => {
  // Set current step
  const lastCompletedIndex = steps.value.findLastIndex((step) =>
    step.isComplete()
  );
  if (lastCompletedIndex === steps.value.length - 1) {
    // If all the steps are completed
    // current step will be the second selectable step
    const secondStep = steps.value.find(
      (step) => step?.isSelectable() && step?.slug !== "program"
    );
    setCurrentStep(secondStep.slug);
  } else {
    const firstIncompleteStep = steps.value[lastCompletedIndex + 1];
    setCurrentStep(firstIncompleteStep.slug);
  }
};

const validateApplication = async () => {
  if (
    store.getters.isLoggedIn &&
    !currentOrder.value.orderId &&
    sessionId.value
  ) {
    const { studentApplications } = store.state;
    const exists = await applicationAlreadyExists({
      programSession: program.programSession,
      studentApplications: studentApplications,
      skipRequest: true,
    });
    showApplicationTakenModal.value = exists;
    duplicateApplication.value = exists;
  }
};

provide("application", {
  duplicateApplication: readonly(duplicateApplication),
  validateApplication,
});

provide("currentOrder", {
  order: currentOrder,
});
provide("programSelectionId", programSelectionId);

onMounted(async () => {
  loading.value = true;
  const promises = [
    loadProgramSessions().then(loadConfiguratorOptions),
    loadUniversityList(),
    preLoadInternshipCredits(),
    loadOrder(),
  ];

  if (store.getters.isLoggedIn) {
    promises.push(store.dispatch("getStudentApplications"));
  }
  // Get profile data if we have a profile id and store is empty
  if (oktaId.value && _.isEmpty(profileData.value)) {
    promises.push(store.dispatch("getProfile", oktaId.value));
  }

  await Promise.allSettled(promises);
  await validateApplication();
  await fetchAndSetSelectedSchool();
  loading.value = false;
});
</script>
