<script setup>
import entitiesService from "@/services/entities.js";
import eventsService from "@/services/events.js";
import { onBeforeMount, ref, watch } from "vue";
import { useStore } from "vuex";
import { API_ENTITY_INFORMATION } from "../../constants";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const entities = ref([store.getters["programManager/getSelectedEntityView"]]);
const selectedEntity = ref(
  store.getters["programManager/getSelectedEntityView"]
);

async function setEntities(entityIds) {
  const response = await entitiesService.getEntities({
    extraParams: { entity_ids: entityIds },
  });
  entities.value = response?.data?.data?.items ?? [];
}

onBeforeMount(async () => {
  if (!selectedEntity?.value?.id){
    store.commit("programManager/setSelectedEntityView", API_ENTITY_INFORMATION);
    selectedEntity.value = store.getters["programManager/getSelectedEntityView"];
  };
  eventsService.getEntityIdsForEventsOccurrences().then((res) => {
    const eventEntityIds = res?.data?.entity_ids;
    setEntities(eventEntityIds);
  });
});

watch(selectedEntity, async (newEntity) => {
  store.commit("programManager/setSelectedEntityView", newEntity);
  await store.dispatch("programManager/setAllCounts");

  const subcategory = route?.meta?.subcategory?.toLowerCase();
  const path = route?.path.toLowerCase();

  try {
    switch (true) {
      case path.includes("events"):
        await store.dispatch("programManager/fetchEventsData");
        break;
      case path.includes("programs"):
        await store.dispatch("programManager/fetchProgramsData");
        break;
      case path.includes("sessions"):
        await store.dispatch("programManager/fetchProgramSessionsData");
        break;
      default:
        console.warn("Unhandled subcategory:", subcategory);
    }
  } catch (error) {
    console.error(
      `Failed to fetch data for subcategory ${subcategory}:`,
      error
    );
  }
});
</script>
<template>
  <div class="entity-select">
    <VSelect
      id="entitySelect"
      v-model="selectedEntity"
      :value="selectedEntity"
      :options="entities"
      :get-option-label="(option) => option.name"
      :clearable="false"
      aria-label="Entity Name"
      class="text-sm"
      placeholder="Enter Entity Name"
    />
  </div>
</template>
<style>
.entity-select .v-select .vs__dropdown-toggle {
  border-style: none;
  width: 300px;
}
</style>
