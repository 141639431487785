<script setup>
import {
  computed,
  onMounted,
  unref,
  watch,
  ref,
  reactive,
  onBeforeMount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToggle, useMediaQuery } from "@vueuse/core";

import CoursesTab from "@/components/ExperiencePage/Algolia/Tabs/DevCoursesTab.vue";
import InternshipTab from "@/components/ExperiencePage/Algolia/Tabs/DevInternshipTab.vue";
import DevProgramsTab from "@/components/ExperiencePage/Algolia/Tabs/DevProgramsTab.vue";
import BreadCrumb from "@/components/shared/BreadCrumb.vue";
import PaginationHeader from "../AlgoliaPaginationHeader.vue";
import SideBar from "../AlgoliaSideBar";
import FiltersIcon from "@/components/svg-icons/FiltersIcon.vue";
import FilterChevronIcon from "@/components/svg-icons/FilterChevronIcon.vue";

import { TABS, algoliaConfigurationMap, countriesTranslationBackMap } from "../constants";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import algoliasearch from "algoliasearch/lite";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { buildFilterStrings2 } from "@/components/ExperiencePage/Algolia/utils";
import {
  useQueryParamsToState,
  useConvertToUrlParams,
} from "@/components/ExperiencePage/Algolia/composables";
import {
  badgeConvertMap,
  INITIAL_FILTERS,
} from "@/components/ExperiencePage/Algolia/AlgoliaSideBar/constants";
import FilterBadgeBar from "@/components/ExperiencePage/Algolia/FilterBadgeBar/index.vue";
import { AisConfigure } from "vue-instantsearch/vue3/es";
import { ALGOLIA_PRESTATE_ADD_FILTER_NAMES } from "../constants";

const store = useStore();
const route = useRoute();
const router = useRouter();

const homeInstitutionId = computed(
    () => store.state.profileData?.colleges?.[0]?.college_id ?? ""
);

const state = reactive({
  filters: {
    ...INITIAL_FILTERS,
  },
});
const algoliaFilters = ref("");
const activeTab = ref(0);
const initialSearchState = ref({});

const [expanded, controlSection] = useToggle();

const searchClient = algoliasearch(
  process.env.MIX_ALGOLIA_APP_ID,
  process.env.MIX_ALGOLIA_API_KEY
);

const profileId = computed(() => {
  return store.state.currentUser?.profile_id ?? "";
});

const profileData = computed(() => {
  return store.state.profileData;
});

const algoliaConfiguration = computed(() => {
  const tabIndex = unref(activeTab);
  return algoliaConfigurationMap[TABS[tabIndex].value] || {};
});

const activeAlgoliaIndex = computed(() => {
  const currentTab = TABS[activeTab.value];
  return currentTab ? currentTab.algoliaIndex : undefined;
});

const currentSearchIndex = computed(() => {
  return searchClient.initIndex(unref(activeAlgoliaIndex));
});

const updateActiveTab = (activeTabIndex) => {
  const activeTabSelected = TABS[activeTabIndex];
  if (activeTabSelected) {
    activeTab.value = activeTabIndex !== -1 ? activeTabIndex : 0;
    router.push({ path: activeTabSelected.slug, query: route.query });
  }
};

const removeOneFilter = (item) => {
  const newFilters = { ...state.filters };
  const { key, label, value } = item;
  if (key === "keyword") {
    newFilters[key] = "";
  } else if (key === "classTitle") {
    newFilters[key] = "";
  } else if (key === "price") {
    newFilters[key] = [];
  } else if (key === "pastPrograms") {
    newFilters[key] = false;
  } else if (key === "startDate" || key === "endDate") {
    newFilters[key] = undefined;
  } else if (key === "sessionId") {
    newFilters[key] = [];
  } else if (key === "timeframe" || key === "areaOfStudy") {
    newFilters[key] = newFilters[key].filter((val) => val !== value);
  } else if (key === "countries") {
    const lookedUpLabel = countriesTranslationBackMap?.[label] || label;
    newFilters[key] = newFilters[key].filter((val) => val !== lookedUpLabel);
  } else {
    newFilters[key] = newFilters[key].filter((val) => val !== label);
  }
  state.filters = newFilters;
};

const resetFilters = (persistedFilters = {}) => {
  const newFilters = {
    ...INITIAL_FILTERS,
  };
  state.filters = { ...newFilters, ...persistedFilters };
};

// generate url query string
const convertFiltersToUrlParams = (clonedFilters) => {
  //query params container
  const query = useConvertToUrlParams(clonedFilters);
  if (!isEmpty(query)) {
    //push the queryString to the browsers url
    router.push({ query, params: { savePosition: true } });
  } else {
    router.push({ path: route.path });
  }
};

const updateFilters = (clonedFilters) => {
  if (clonedFilters) {
    algoliaFilters.value = buildFilterStrings2(
      unref(algoliaConfiguration),
      clonedFilters
    );
  }
};

watch(
  () => state.filters,
  (newFilters, oldFilters) => {
    if (!isEqual(newFilters, oldFilters)) {
      const clonedFilters = cloneDeep(newFilters);
      updateFilters(clonedFilters);
      convertFiltersToUrlParams(clonedFilters);
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => route.path,
  (newPath, oldPath) => {
    if (newPath !== oldPath) {
      const activeTabSlug = newPath;
      const activeTabIndex = TABS.findIndex(
        (tab) => tab.slug === activeTabSlug
      );
      activeTab.value = activeTabIndex !== -1 ? activeTabIndex : 0;
      const oldKeywords = state.filters?.keyword;
      resetFilters({keyword: oldKeywords});
      updateFilters(state.filters);
      convertFiltersToUrlParams(state.filters);
    }
  },
  { immediate: true }
);

onBeforeMount(() => {
  const hasQueryParams = Object.keys(route.query).length > 0;
  if (hasQueryParams) {
    //make a search request if query params exist on mount
    state.filters = useQueryParamsToState(route, INITIAL_FILTERS);
  } else {
    resetFilters();
  }
  const clonedFilters = cloneDeep(state.filters);
  updateFilters(clonedFilters);

  const newInitialSearchState = {};
  newInitialSearchState[activeAlgoliaIndex.value] = { refinementList: {} };
  let foundValues = false;

  ALGOLIA_PRESTATE_ADD_FILTER_NAMES.forEach((selectName) => {
    const facetName = unref(algoliaConfiguration)[selectName];
    const filterValue = state.filters[selectName];
    if (
      facetName &&
      filterValue &&
      ((Array.isArray(filterValue) && filterValue?.length > 0) ||
        (!Array.isArray(filterValue)))
    ) {
      newInitialSearchState[activeAlgoliaIndex.value].refinementList[
        facetName
      ] = filterValue;
      foundValues = true;
    }
  });

  initialSearchState.value = foundValues ? newInitialSearchState : undefined;
});

onMounted(async () => {
  if (profileId.value && isEmpty(profileData.value)) {
    await store.dispatch("getProfile", profileId.value);
  }

  // Check if the screen width is less than or equal to a certain value (e.g., 768px)
  const isMobile = useMediaQuery("(max-width: 768px)");
  if (!isMobile) {
    expanded.value = true; // Initially expanded on non-mobile devices
  }

  if (store.state.currentUser) {
    await store.dispatch("getStudentApplications");
  }
});
</script>

<template>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="activeAlgoliaIndex"
    :initial-ui-state="initialSearchState"
  >
    <ais-configure :filters="algoliaFilters" />
    <div id="results-container" class="font-montserrat md:flex">
      <section class="bg-gray-10">
        <BreadCrumb class="md:hidden pl-14 pt-1" custom-spacer experience-page
          >/
        </BreadCrumb>
        <header
          class="flex md:hidden items-center border-b border-gray-200 h-16 pl-16 pr-20"
        >
          <button
            aria-controls="expand-filters"
            :aria-expanded="expanded"
            class="flex items-center text-lg w-full justify-between text-indigo-base font-semibold"
            @click="controlSection()"
          >
            <span
              class="flex items-center text-base tracking-widest text-left uppercase"
            >
              <FiltersIcon />
              Filters
            </span>
            <FilterChevronIcon />
          </button>
        </header>
        <div
          id="side-bar-menu"
          class="md:block bg-gray-75 md:w-72 xl:w-96 md:px-8"
          :class="{ hidden: !expanded }"
        >
          <SideBar
            v-model="state.filters"
            :algolia-configuration="algoliaConfiguration"
            :current-search-index="currentSearchIndex"
            @close-filters="controlSection"
          />
        </div>
      </section>
      <div id="results-content" class="w-full">
        <div class="bg-white w-full px-2 py-8 sm:px-0">
          <TabGroup :selected-index="activeTab" @change="updateActiveTab">
            <TabList
              :class="`flex space-x border-0 sm:border-b ${primaryColorBorder} font-montserrat`"
            >
              <Tab
                v-for="tab in TABS"
                :key="tab.name"
                v-slot="{ selected }"
                as="template"
              >
                <div class="flex-1 sm:flex-none focus:outline-none">
                  <button
                    :class="[
                      selected
                        ? `${primaryColorClassOutlinedNoHover} bg-white border-bottom-white`
                        : `${primaryColorClassBG} ${primaryColorBorder} text-white`,
                      'rounded-t-lg border-2  py-4 px-7 w-[266px] font-bold text-base mx-2 hidden sm:flex -m-px uppercase justify-center',
                    ]"
                  >
                    {{ tab.name }}
                  </button>
                  <button
                    :class="[
                      selected
                        ? `font-bold ${primaryColorBorder}`
                        : `${primaryColorBorder} font-medium`,
                      `${primaryColorClass} bg-white flex-1	whitespace-nowrap py-4 px-1 border-b-2 text-xs w-full sm:hidden border-r-2 boder-right-transparent uppercase`,
                    ]"
                  >
                    {{ tab.name }}
                  </button>
                </div>
              </Tab>
            </TabList>

            <div id="sorting-container" class="w-full px-5 lg:px-10">
              <PaginationHeader class="mb-5 mt-10 hidden md:block" />
              <div
                class="mt-10 md:mt-0 ls:pl-10 mb-9 flex flex-col md:flex-row md:justify-between md:items-center max-w-1310p"
              >
                <PaginationHeader class="mb-5 md:hidden" />
                <FilterBadgeBar
                  :filters="state.filters"
                  :value-config="badgeConvertMap"
                  @clear-all="resetFilters"
                  @remove-one-filter="removeOneFilter"
                  :homeInstitutionId="homeInstitutionId"
                />
              </div>
              <hr class="w-full text-indigo-light mb-45p" />
            </div>
            <TabPanels
              :unmount="false"
              class="bg-white lg:px-10 gap-10 lg:flex lg:flex-col pb-18"
            >
              <TabPanel>
                <DevProgramsTab v-show="activeTab === 0" />
              </TabPanel>
              <TabPanel>
                <InternshipTab v-show="activeTab === 1" />
              </TabPanel>
              <TabPanel>
                <CoursesTab v-show="activeTab === 2" />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </div>
  </ais-instant-search>
</template>
