<script>
export default {
  name: "DetailSection",
};
</script>

<script setup>
import { ref, unref, reactive, computed } from "vue";
import { format as formatDate, parseISO } from "date-fns";
import { useVModel } from "@vueuse/core";
import { formattedPriceFromCents } from "@/util/formatter";
import {
  ClipboardIcon,
  ExclamationTriangleIcon,
} from "@heroicons/vue/24/outline";
import {
  CLASSES_EDUCATIONAL_FEE,
  MEDICAL_INSURANCE,
  SPAIN_MEDICAL_INSURANCE,
  entityTypes,
} from "@/components/program-manager/sessions/constants";
import {
  debounceSearchWrapper,
  useGetEntities,
} from "@/components/program-manager/sessions/composable";
import {
  ORDER_STATUS,
  REQUESTED_ROOM_TYPE,
  ORDER_PRODUCT_STATUS,
  ERROR_TIMEOUT,
  SUCCESS_TIMEOUT,
  TIERS_OBJ,
} from "@/constants";
import {
  headers,
  ORDER_STATUSES,
  ORDER_CONTRACT_SAVE_ERROR_MSG,
  ORDER_CONTRACT_SAVE_SUCCESS_MSG,
} from "@/components/StudentFulfillment/components/OrderDetail/sections/Details/constants";
import Spinner from "@/components/helpers/Spinner.vue";
import VSelectCaret from "@/components/shared/select/VSelectCaret.vue";
import SearchableSelect from "@/components/shared/select/SearchableSelect.vue";
import BasicTextInput from "@/components/shared/input/BasicTextInput.vue";
import BaseButton from "@/components/shared/Button/BaseButton";
import ButtonWithSpinner from "@/components/forms/SharedComponents/ButtonWithSpinner.vue";
import ExpandableRows from "@/components/StudentFulfillment/components/OrderDetail/sections/Details/ExpandableRows.vue";
import ExpandableItem from "@/components/StudentFulfillment/components/OrderDetail/sections/Details/ExpandableItem.vue";
import HousingFulfillmentModal from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal";
import AddonsFulfillmentModal from "@/components/StudentFulfillment/components/modals/AddonsFulfillmentModal";
import ExcursionsFulfillmentModal from "@/components/StudentFulfillment/components/modals/ExcursionsFulfillmentModal";
import ClassesFeeFulfillmentModal from "@/components/StudentFulfillment/components/modals/ClassesFeeFulfillmentModal";
import InsuranceFulfillmentModal from "@/components/StudentFulfillment/components/modals/InsuranceFulfillmentModal";
import { useHousingQuestionnaire } from "@/composables/useHousingQuestionnaire";
import internshipService from "@/services/internship";
import {
  hasOrdersWritePermission,
  hasOrdersAdminPermission,
} from "@/composables/authorization";
import housingService from "@/services/housings";
import eventsService from "@/services/events";
import ordersService from "@/services/orders";
import profileService from "@/services/profile";
import { useQuery } from "@tanstack/vue-query";
import { useToast } from "vue-toast-notification";
import {
  createColumnHelper,
  useVueTable,
  getCoreRowModel,
} from "@tanstack/vue-table";

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {},
  },
  programSession: {
    type: Object,
    default: () => {},
  },
});

const toast = useToast();
const emit = defineEmits(["update:modelValue"]);
const orderData = useVModel(props, "modelValue", emit, { passive: true });
const selectedEntity = ref({});
const contractNumber = ref("");
const housingModalIsOpen = ref(false);
const isEditingOrderContract = ref(false);
const isSavingContract = ref(false);
const excursionsModalIsOpen = ref(false);
const addonsModalIsOpen = ref(false);
const insuranceModalIsOpen = ref(false);
const currentEditItemStatus = ref({});
const currentEditItems = ref([]);
const loadingExcursions = ref(false);
const classesFeeModalIsOpen = ref(false);
const currentEditItemClassesFee = ref({});
const orderStatusOptions = computed(() => Object.values(ORDER_STATUS) || []);
const sectionsExpandedStates = reactive({
  internships: false,
  housing: false,
  occurrences: false,
});

const orderProductStatusOptions = computed(
  () => Object.values(ORDER_PRODUCT_STATUS) || []
);

const sessionId = computed(() => orderData?.value?.order?.session_id);
const internshipIds = computed(
  () => orderData?.value?.order?.internship_ids || []
);

const miscProductIds = computed(
  () =>
    orderData?.value?.product_details?.misc_products?.map(
      (misc) => misc?.misc_product?.id
    ) || []
);

const canEditOrderItem = computed(
  () => orderData?.value?.order?.status !== ORDER_STATUSES.Fulfilled
);

const medicalInsuranceIndex = computed(() =>
  miscProductIds?.value?.findIndex(
    (item) =>
      item === MEDICAL_INSURANCE.id || item === SPAIN_MEDICAL_INSURANCE.id
  )
);

const classesFeesIndex = computed(() =>
  miscProductIds?.value?.indexOf(CLASSES_EDUCATIONAL_FEE.id)
);

const { getHousingQuestionnaire } = useHousingQuestionnaire();

const orderRoomHousingId = computed(
  () => orderData?.value?.product_details?.rooms?.[0]?.room?.unit?.housing?.id
);

const {
  execute: executeGetEntities,
  state: entitiesOptions,
  isLoading: entitiesOptionsLoading,
} = useGetEntities(
  { immediate: true, throwError: false, resetOnExecute: true },
  {
    extraParams: {
      account_types: [entityTypes.host_institution],
    },
  }
);

const fetchEntitiesOptions = debounceSearchWrapper(executeGetEntities, 250, {
  extraParams: {
    account_types: [entityTypes.home_institution, entityTypes.host_institution],
  },
});

const {
  isLoading: isLoadingProfile,
  data: profileData,
  refetch: profileRefetch,
} = useQuery({
  queryKey: ["fetchProfile", orderData?.value?.order?.participant_id],
  queryFn: () =>
    profileService.getProfile(orderData?.value?.order?.participant_id),
});

const {
  isLoading: isLoadingHousingQuestionnaire,
  data: housingQuestionnaireData,
  refetch: housingQuestionnaireRefetch,
} = useQuery({
  queryKey: ["fetchHousingQuestionnaire", orderData?.value?.order?.id],
  queryFn: () => getHousingQuestionnaire(orderData?.value?.order?.id),
  enabled: sectionsExpandedStates.housing,
});

const {
  isLoading: isLoadingHousing,
  data: housingData,
  refetch: refetchHousing,
} = useQuery({
  queryKey: ["fetchHousing", orderRoomHousingId.value],
  queryFn: () => housingService.getHousingById(orderRoomHousingId.value, true),
  enabled: Boolean(orderRoomHousingId.value),
});

const {
  isLoading: isLoadingOrderInternships,
  data: orderInternshipsData,
  refetch: orderInternshipRefetch,
} = useQuery({
  queryKey: ["fetchInternships", orderData?.value?.order?.internship_ids],
  queryFn: () =>
    internshipService.getMany({
      internship_ids: orderData?.value?.order?.internship_ids,
    }),
  enabled: sectionsExpandedStates.internships,
});

const {
  isLoading: isLoadingOrderEventOccurrences,
  data: orderEventOccurrencesData,
  refetch: orderEventOccurrencesRefetch,
} = useQuery({
  queryKey: [
    "fetchEventOccurrences",
    orderData?.value?.order?.event_occurrence_ids,
  ],
  queryFn: () =>
    eventsService.getOccurrences({
      occurrence_ids: orderData?.value?.order?.event_occurrence_ids,
    }),
  enabled: sectionsExpandedStates.occurrences,
});

const closeHousingModal = async () => {
  housingModalIsOpen.value = false;
  unref(refetchHousing)();
};

const loadHousingQuestionnaire = (isExpanded) => {
  if (isExpanded && !sectionsExpandedStates.housing) {
    sectionsExpandedStates.housing = true;
    unref(housingQuestionnaireRefetch)();
  }
};

const loadInternships = (isExpanded) => {
  if (isExpanded && !sectionsExpandedStates.internships) {
    sectionsExpandedStates.internships = true;
    unref(orderInternshipRefetch)();
  }
};

const loadOccurrences = (isExpanded) => {
  if (isExpanded && !sectionsExpandedStates.occurrences) {
    sectionsExpandedStates.occurrences = true;
    unref(orderEventOccurrencesRefetch)();
  }
};

const getAnswer = (question) => {
  if (question?.answer) {
    return question?.answer;
  } else if (question?.answers) {
    return question?.answers.map((answer) => answer?.answer_text).join(", ");
  }
};

const entityOptionsFilter = (options) =>
  options.filter((option) => !!option.ope_id);

const orderInstitutionOptions = computed(
  () =>
    [
      ...entityOptionsFilter(entitiesOptions.value?.items || []),
      { ope_id: "dummy", id: null, name: "I dont know my school" },
    ] || []
);

const disableOrderContractButton = computed(
  () =>
    ![
      ORDER_STATUS.draft,
      ORDER_STATUS.paidConfirmed,
      ORDER_STATUS.submitted,
    ].includes(orderData.value?.order?.status)
);

const isInstitutionUnsyncedWithProfile = computed(
  () =>
    profileData?.value?.data?.data?.colleges &&
    !!orderData.value?.order?.home_institution_id &&
    !profileData?.value?.data?.data?.colleges
      ?.map((item) => item?.college_id)
      ?.includes(orderData.value?.order?.home_institution_id)
);

const housingSubmissionData = computed(
  () => housingQuestionnaireData?.value?.submission_data
);

const orderPaymentProcessing = computed(
  () => orderData?.value?.payment_processing
);

const questionaryQuestionsAndAnswers = computed(() => {
  let questionaryData = {};
  const questions = housingSubmissionData?.value?.question_responses;
  if (questions) {
    for (const question of questions) {
      questionaryData[question?.model] = getAnswer(question);
    }
  }
  return questionaryData;
});

const normalizedHousingQuestionnaire = computed(() => ({
  "Roommate(s)":
    housingSubmissionData?.value?.requested_roommates
      ?.map((mate) => `${mate.first_name} ${mate.last_name}`)
      .join(", ") ?? undefined,
  "Unitmate Requests":
    housingSubmissionData?.value?.requested_housemates
      ?.map((mate) => `${mate.first_name} ${mate.last_name}`)
      .join(", ") ?? undefined,
  Accomodation:
    questionaryQuestionsAndAnswers?.value?.housingRequirements ?? undefined,
  "Student Identifies As":
    profileData?.value?.data?.data?.gender_identity ?? undefined,
  "Student Requests":
    questionaryQuestionsAndAnswers?.value?.comfortableLivingIn ?? undefined,
  Smoking: questionaryQuestionsAndAnswers?.value?.smoking ?? undefined,
  Pets: questionaryQuestionsAndAnswers?.value?.livingWithPets ?? undefined,
  Meals:
    questionaryQuestionsAndAnswers?.value?.dietaryRestrictions ?? undefined,
  "Description of Self":
    questionaryQuestionsAndAnswers?.value?.describeMyself ?? undefined,
  "Additional Housing Request":
    questionaryQuestionsAndAnswers?.value?.additionalHousingRequest ??
    undefined,
}));

const internshipDataMap = computed(
  () =>
    orderInternshipsData?.value?.data?.data?.items?.reduce(
      (acc, current) => ({
        ...acc,
        [current?.id]: {
          company: current?.company?.name,
          street_address_1: current?.street_address_1,
          street_address_2: current?.street_address_2,
          city: current?.city,
          country: current?.country,
          postal_code: current?.postal_code,
        },
      }),
      {}
    ) || {}
);

const eventsDatesMap = computed(
  () =>
    orderEventOccurrencesData?.value?.data?.data?.items?.reduce(
      (acc, current) => ({
        ...acc,
        [current?.id]: {
          location_city:
            current?.associated_event?.event_locations?.[0]?.city?.city,
          location_country:
            current?.associated_event?.event_locations?.[0]?.city?.country
              ?.name,
          event_duration: current?.associated_event?.duration ?? 0,
          event_duration_unit: current?.associated_event?.duration_units ?? "",
          start_date: current?.start_date_time,
          end_date: current?.end_date_time,
        },
      }),
      {}
    ) || {}
);

const buildSessionMoreInfo = () => {
  return {
    country: `${
      orderPaymentProcessing.value?.order_metadata?.session_country || ""
    }`,
    terms: orderPaymentProcessing.value?.order_metadata?.terms || "",
    date: `${formatDate(
      parseISO(orderPaymentProcessing.value?.order_metadata?.start_date),
      "MM/dd/yyyy"
    )} - ${
      formatDate(
        parseISO(orderPaymentProcessing.value?.order_metadata?.end_date),
        "MM/dd/yyyy"
      ) || ""
    }`,
    credits: `${orderData?.value?.order?.credits || 0} Credits`,
  };
};

const buildOccurrenceMoreInfo = (occurrenceId) => {
  let date = "";
  let location = "";

  if (
    eventsDatesMap?.value?.[occurrenceId]?.location_city &&
    eventsDatesMap?.value?.[occurrenceId]?.location_country
  ) {
    location = `${eventsDatesMap?.value?.[occurrenceId]?.location_city}, ${eventsDatesMap?.value?.[occurrenceId]?.location_country}`;
  }

  if (eventsDatesMap?.value?.[occurrenceId]?.start_date) {
    date = `${formatDate(
      parseISO(eventsDatesMap?.value?.[occurrenceId]?.start_date),
      "MM/dd/yyyy"
    )}`;
  }

  if (
    eventsDatesMap?.value?.[occurrenceId]?.start_date &&
    eventsDatesMap?.value?.[occurrenceId]?.end_date
  ) {
    date = `${date} - ${formatDate(
      parseISO(eventsDatesMap?.value?.[occurrenceId]?.end_date),
      "MM/dd/yyyy"
    )}`;
  }

  return {
    date: date,
    location: location,
    duration: `${eventsDatesMap?.value?.[occurrenceId]?.event_duration} ${eventsDatesMap?.value?.[occurrenceId]?.event_duration_unit}`,
  };
};

const getTableRowData = (
  id,
  type,
  name,
  company,
  info,
  studentPays,
  institutionPays,
  totalPrice,
  status
) => ({
  id: id,
  Type: type,
  Name: name,
  "Company/Vendor": company,
  "More Info": info,
  "Student Pays": studentPays,
  "Institution Pays": institutionPays,
  "Total Price": totalPrice,
  Status: status,
});

const getSessionTableRow = () =>
  getTableRowData(
    null,
    `Academic: ${orderPaymentProcessing.value?.order_metadata?.program_type} Session`,
    {
      name: orderPaymentProcessing.value?.order_metadata?.program_name,
      subtitle: `(SF Session ID: ${
        props.programSession?.items?.salesforce_id || ""
      })`,
    },
    "",
    buildSessionMoreInfo(),
    `${formattedPriceFromCents(
      orderData?.value?.order?.student_total_price_in_cents
    )}`,
    `${formattedPriceFromCents(
      orderData?.value?.order?.institution_total_price_in_cents
    )}`,
    `${formattedPriceFromCents(orderData?.value?.order?.total_price_in_cents)}`,
    null
  ) || {};

const getHousingTableRow = () =>
  getTableRowData(
    null,
    "Housing",
    { name: housingData?.value?.data?.data?.name ?? "" },
    housingData?.value?.data?.data?.vendor?.name ?? "",
    {
      housing_info: !!orderRoomHousingId.value
        ? `${housingData?.value?.data?.data?.type ?? ""} / ${
            housingData?.value?.data?.data?.tier?.name
              ? TIERS_OBJ[housingData?.value?.data?.data?.tier?.name]
              : null ?? ""
          } / ${
            REQUESTED_ROOM_TYPE[
              orderData?.value?.product_details?.rooms?.[0]?.room?.type
            ] ?? ""
          }`
        : "",
    },
    !!orderRoomHousingId.value
      ? `${formattedPriceFromCents(
          orderData?.value?.product_details?.rooms?.[0]
            ?.student_total_price_in_cents
        )}`
      : "",
    !!orderRoomHousingId.value
      ? `${formattedPriceFromCents(
          orderData?.value?.product_details?.rooms?.[0]
            ?.institution_total_price_in_cents
        )}`
      : "",
    !!orderRoomHousingId.value
      ? `${formattedPriceFromCents(
          orderData?.value?.product_details?.rooms?.[0]?.total_price_in_cents
        )}`
      : "",
    orderData?.value?.product_details?.rooms?.[0]?.status ?? ""
  ) || {};

const getInsuranceTableRow = () => {
  const insuranceItem = orderData?.value?.product_details?.misc_products?.find(
    (misc) =>
      misc?.misc_product?.id == MEDICAL_INSURANCE.id ||
      misc?.misc_product?.id == SPAIN_MEDICAL_INSURANCE.id
  );
  return insuranceItem
    ? getTableRowData(
        insuranceItem?.misc_product?.id,
        "Insurance",
        { name: insuranceItem?.misc_product?.name ?? "" },
        "",
        "",
        `${formattedPriceFromCents(
          insuranceItem?.student_total_price_in_cents
        )}`,
        `${formattedPriceFromCents(
          insuranceItem?.institution_total_price_in_cents
        )}`,
        `${formattedPriceFromCents(insuranceItem?.total_price_in_cents)}`,
        insuranceItem?.status ?? ""
      )
    : {};
};

const getInternshipTableRows = () =>
  orderData?.value?.product_details?.internships.map((internship) =>
    getTableRowData(
      internship?.internship?.id,
      `Academic: Internships: ${orderData?.value?.product_details?.internships?.length}`,
      internship?.internship?.title ?? "",
      internshipDataMap?.value?.[internship?.internship?.id]?.company ?? "",
      {
        street_address_1:
          internshipDataMap?.value?.[internship?.internship?.id]
            ?.street_address_1,
        street_address_2:
          internshipDataMap?.value?.[internship?.internship?.id]
            ?.street_address_2,
        city: internshipDataMap?.value?.[internship?.internship?.id]?.city,
        country:
          internshipDataMap?.value?.[internship?.internship?.id]?.country,
        postal_code:
          internshipDataMap?.value?.[internship?.internship?.id]?.postal_code,
      },
      `${formattedPriceFromCents(internship?.student_total_price_in_cents)}`,
      `${formattedPriceFromCents(
        internship?.institution_total_price_in_cents
      )}`,
      `${formattedPriceFromCents(internship?.total_price_in_cents)}`,
      internship?.status ?? ""
    )
  ) || [];

const getOccurrencesTableRows = () =>
  orderData?.value?.product_details?.event_occurrences?.map((event) =>
    getTableRowData(
      event?.event_occurrence?.id,
      `Academic: Excursions: ${orderData?.value?.product_details?.event_occurrences?.length}`,
      event?.event_occurrence?.event_title ?? "",
      "",
      buildOccurrenceMoreInfo(event?.event_occurrence?.id),
      `${formattedPriceFromCents(event?.student_total_price_in_cents)}`,
      `${formattedPriceFromCents(event?.institution_total_price_in_cents)}`,
      `${formattedPriceFromCents(event?.total_price_in_cents)}`,
      event?.status ?? ""
    )
  ) || [];

const getAddonsTableRows = () =>
  orderData?.value?.product_details?.misc_products
    ?.filter(
      (misc) =>
        misc?.misc_product?.id !== MEDICAL_INSURANCE.id &&
        misc?.misc_product?.id !== CLASSES_EDUCATIONAL_FEE.id &&
        misc?.misc_product?.id !== SPAIN_MEDICAL_INSURANCE.id
    )
    .map((misc) =>
      getTableRowData(
        misc?.misc_product?.id,
        `Addons: ${orderData?.value?.product_details?.misc_products?.length}`,
        misc?.misc_product?.name ?? "",
        "",
        "",
        `${formattedPriceFromCents(misc?.student_total_price_in_cents)}`,
        `${formattedPriceFromCents(misc?.institution_total_price_in_cents)}`,
        `${formattedPriceFromCents(misc?.total_price_in_cents)}`,
        misc?.status ?? ""
      )
    ) || [];

const getClassesAcademicFeesRow = () =>
  getTableRowData(
    CLASSES_EDUCATIONAL_FEE.id,
    "Academics: Classes",
    { name: "Classes Fee" },
    "",
    "",
    `${formattedPriceFromCents(
      orderData?.value?.product_details?.misc_products[classesFeesIndex?.value]
        ?.student_total_price_in_cents
    )}`,
    `${formattedPriceFromCents(
      orderData?.value?.product_details?.misc_products[classesFeesIndex?.value]
        ?.institution_total_price_in_cents
    )}`,
    `${formattedPriceFromCents(
      orderData?.value?.product_details?.misc_products[classesFeesIndex?.value]
        ?.total_price_in_cents
    )}`,
    orderData?.value?.product_details?.misc_products[classesFeesIndex?.value]
      ?.status ?? ""
  ) || {};

const getTableData = () => {
  return [
    getSessionTableRow(),
    getHousingTableRow(),
    getInsuranceTableRow(),
    getClassesAcademicFeesRow(),
    ...getInternshipTableRows(),
    ...getOccurrencesTableRows(),
    ...getAddonsTableRows(),
  ];
};

const columnHelper = createColumnHelper();

const table = useVueTable(
  reactive({
    get data() {
      return getTableData();
    },
    columns: headers.map((header) => {
      return columnHelper.accessor(header, {
        cell: (info) => info.getValue(),
        header,
      });
    }),
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })
);

const sessionRow = computed(() => {
  return table.getRowModel()?.rows?.[0];
});

const housingRow = computed(() => {
  return table.getRowModel().rows?.[1];
});

const insuranceRow = computed(() => {
  return table.getRowModel().rows?.[2];
});

const classesFeesRow = computed(() => {
  return table.getRowModel().rows?.[3];
});

const internshipRows = computed(() => {
  return table
    .getRowModel()
    .rows.filter(
      (row) =>
        row.original.Type ===
        `Academic: Internships: ${orderData?.value?.product_details?.internships?.length}`
    );
});

const eventRows = computed(() => {
  return (
    table
      .getRowModel()
      .rows.filter(
        (row) =>
          row.original.Type ===
          `Academic: Excursions: ${orderData?.value?.product_details?.event_occurrences?.length}`
      ) || []
  );
});

const addonsRows = computed(() => {
  return (
    table
      .getRowModel()
      .rows.filter(
        (row) =>
          row.original.Type ===
          `Addons: ${orderData?.value?.product_details?.misc_products?.length}`
      ) || []
  );
});

const copyOrderId = (orderId) => {
  if (!orderId) return;
  navigator.clipboard.writeText(orderId);
};

const openStatusModal = (id, productType, objectKey, isAddon = true) => {
  currentEditItemStatus.value = orderData?.value?.product_details?.[
    productType
  ]?.find((item) => item?.[objectKey]?.id === id);
  if (currentEditItemStatus.value) {
    currentEditItemStatus.value.id = id;
  }
  if (isAddon) addonsModalIsOpen.value = true;
  else insuranceModalIsOpen.value = true;
};

const buildExcursionsData = async () => {
  loadingExcursions.value = true;
  if (!!orderData?.value?.order?.event_occurrence_ids.length) {
    await unref(orderEventOccurrencesRefetch)();
    currentEditItems.value =
      unref(orderEventOccurrencesData)?.data?.data?.items?.map((item) => {
        const orderEventData = unref(
          orderData
        )?.product_details?.event_occurrences?.find(
          (event) => event.id === item.id
        );
        return {
          ...item,
          new_product_id: item.id,
          name: item?.associated_event?.title,
          duration: `${item?.associated_event?.duration} ${item?.associated_event?.duration_units}`,
          title: orderEventData?.event_occurrence?.event_title || "",
          total_price_in_cents: orderEventData?.total_price_in_cents || 0,
          status: orderEventData?.status,
          quantity: orderEventData?.quantity,
          cancellation_reason: orderEventData?.cancellation_reason || "",
        };
      }) || [];
  }
  loadingExcursions.value = false;
};

const openExcursionModal = () => {
  buildExcursionsData();
  excursionsModalIsOpen.value = true;
};

const updateSavedExcursionData = (dataResponse, item) => {
  if (item) {
    currentEditItems.value.push(item);
  }
  orderData.value = dataResponse.data;

  unref(orderEventOccurrencesRefetch)();
};

const closeStatusModal = (orderResponse, isAddon = true) => {
  if (orderResponse?.data?.order?.id) {
    orderData.value = orderResponse.data;
  }
  currentEditItemStatus.value = {};
  if (isAddon) addonsModalIsOpen.value = false;
  else insuranceModalIsOpen.value = false;
};

const closeExcursionsFulfillmentModal = () => {
  currentEditItems.value = [];
  excursionsModalIsOpen.value = false;
};

const openClassesFeeModal = (id, productType, objectKey) => {
  currentEditItemClassesFee.value = orderData?.value?.product_details?.[
    productType
  ]?.find((item) => item?.[objectKey]?.id === id);
  if (
    typeof currentEditItemClassesFee.value == "object" &&
    !!currentEditItemClassesFee.value
  ) {
    currentEditItemClassesFee.value.id = id;
    currentEditItemClassesFee.value.sessionPrice =
      props.programSession?.items?.class_academic_fees_price_in_cents ?? 0;
  }
  classesFeeModalIsOpen.value = true;
};

const closeClassesFeeModal = (orderResponse) => {
  if (orderResponse?.data?.order?.id) {
    orderData.value = orderResponse.data;
  }
  currentEditItemClassesFee.value = {};
  classesFeeModalIsOpen.value = false;
};

const toggleOrderContractFields = () => {
  isEditingOrderContract.value = !isEditingOrderContract.value;
  contractNumber.value = orderData.value?.order?.billing_contract_id;
  selectedEntity.value = {
    ope_id: orderData.value?.order?.ope_id,
    name: orderData.value?.order?.home_institution_name,
    id: orderData.value?.order?.home_institution_id,
  };
};

const saveOrderContract = async () => {
  try {
    const orderId = orderData.value?.order?.id;
    const homeInstititutionId = selectedEntity.value?.id;
    let contractId = contractNumber.value;

    if (!homeInstititutionId) {
      contractId = null;
    }

    isSavingContract.value = true;

    const updatedOrder = await ordersService.updateOrderContract({
      orderId: orderId,
      payload: {
        home_institution_id: homeInstititutionId,
        billing_contract_id: contractId,
      },
    });
    if (updatedOrder) {
      orderData.value = updatedOrder?.data?.data;

      unref(profileRefetch)();

      toast.open({
        message: ORDER_CONTRACT_SAVE_SUCCESS_MSG,
        type: "success",
        position: "bottom",
        duration: SUCCESS_TIMEOUT,
      });
    } else {
      toast.open({
        message: ORDER_CONTRACT_SAVE_ERROR_MSG,
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    }
  } catch (err) {
    toast.open({
      message: ORDER_CONTRACT_SAVE_ERROR_MSG,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {
    toggleOrderContractFields();
    isSavingContract.value = false;
  }
};
</script>

<template>
  <HousingFulfillmentModal
    :order="orderData"
    :modal-is-open="housingModalIsOpen"
    @close-modal="closeHousingModal"
  />
  <InsuranceFulfillmentModal
    :order-id="orderData?.order?.id"
    :modal-is-open="insuranceModalIsOpen"
    :line-item="currentEditItemStatus"
    :profile-name="orderData?.order?.participant_fullname"
    :status-options="orderProductStatusOptions"
    @close-modal="closeStatusModal"
  />
  <AddonsFulfillmentModal
    :order-id="orderData?.order?.id"
    :modal-is-open="addonsModalIsOpen"
    :status-options="orderProductStatusOptions"
    :session-id="sessionId"
    :profile-name="orderData?.order?.participant_fullname"
    :selected-misc-products="orderData?.product_details?.misc_products"
    @close-modal="closeStatusModal"
  />
  <ExcursionsFulfillmentModal
    :order-id="orderData?.order?.id"
    :modal-is-open="excursionsModalIsOpen"
    :profile-name="orderData?.order?.participant_fullname"
    :program-session="programSession"
    :excursions-data="currentEditItems"
    :loading-excursions="loadingExcursions"
    @update-saved-excursion-data="updateSavedExcursionData"
    @close-modal="closeExcursionsFulfillmentModal"
  />
  <ClassesFeeFulfillmentModal
    :order-id="orderData?.order?.id"
    :order-status="orderData?.order?.status"
    :modal-is-open="classesFeeModalIsOpen"
    :line-item="currentEditItemClassesFee"
    :status-options="orderProductStatusOptions"
    @close-modal="closeClassesFeeModal"
  />
  <div
    class="px-4 py-4 space-y-2 space-x-4 flex flex-col bg-blue-200 md:pt-2 md:pb-4 md:items-center md:flex-row sm:justify-between"
  >
    <div
      class="group items-start flex justify-between md:flex-col md:justify-normal"
    >
      <span class="ml-2 font-semibold text-sm md:text-md md:ml-0">
        Order ID
      </span>
      <div class="flex">
        <span class="text-sm md:text-md">
          {{ orderData?.order?.id }}
        </span>
        <button
          class="text-sm md:text-md"
          @click="copyOrderId(orderData?.order?.id)"
        >
          <ClipboardIcon class="h-4 ml-2 mb-1 text-teal-900" />
        </button>
      </div>
    </div>
    <div
      class="group items-start flex justify-between md:flex-col md:justify-normal"
    >
      <span class="font-semibold text-sm md:text-md">
        Order Created Date
      </span>
      <span class="text-sm md:text-md">
        {{ formatDate(parseISO(orderData?.order?.created_at), "MM/dd/yyyy") }}
      </span>
    </div>
    <VSelectCaret
      outer-classes="w-full checkbox-placeholder md:w-52"
      v-model="orderData.order.status"
      field-label="Order Status"
      field-label-classes="font-semibold text-sm md:text-md"
      :options="orderStatusOptions"
      :disabled="!hasOrdersWritePermission"
    />
    <div
      v-if="hasOrdersAdminPermission"
      class="hidden mx-4 self-stretch w-[3px] h-20 bg-blue-100 md:inline-block"
    ></div>
    <div v-if="hasOrdersAdminPermission">
      <div v-if="!isEditingOrderContract">
        <div
          class="group items-start flex justify-between md:flex-col md:justify-normal"
        >
          <span class="font-semibold text-sm md:text-md">
            Contract Number
          </span>
          <span class="text-sm md:text-md">
            {{ orderData?.order?.billing_contract_id || "No contract number" }}
          </span>
        </div>
      </div>
      <BasicTextInput
        v-else
        v-model="contractNumber"
        outer-classes="w-full checkbox-placeholder md:w-52 md:m-2"
        field-label-classes="font-semibold text-sm md:text-md"
        field-label="Contract Number"
        label="contract"
      />
    </div>
    <div v-if="hasOrdersAdminPermission">
      <div v-if="!isEditingOrderContract">
        <div
          class="group items-start flex justify-between md:flex-col md:justify-normal"
        >
          <span class="font-semibold text-sm md:text-md">
            Home University
          </span>
          <span class="flex items-center text-sm md:text-md">
            {{
              orderData?.order?.home_institution_name ||
              "No home institution selected"
            }}
            <button
              v-if="isInstitutionUnsyncedWithProfile && !isLoadingProfile"
              class="ml-2"
              data-tippy-content="The order institution is unsynced with the profile college."
            >
              <ExclamationTriangleIcon class="text-help-warning w-4 h-4" />
            </button>
          </span>
        </div>
      </div>
      <SearchableSelect
        v-else
        v-model="selectedEntity"
        :filter="(options) => entityOptionsFilter(options)"
        :options="orderInstitutionOptions"
        :loading="entitiesOptionsLoading"
        @search="fetchEntitiesOptions"
        :clear-search-on-blur="() => false"
        :deselect-from-dropdown="true"
        input-classes="w-full checkbox-placeholder md:w-52"
        field-label-classes="font-semibold text-sm md:text-md"
        placeholder="Search and select a institution"
        field-label="Home University"
        scroll-input="hidden"
        label="name"
      />
    </div>
    <div v-if="!isEditingOrderContract && hasOrdersAdminPermission">
      <BaseButton
        class="w-full text-sm h-10 mt-2 md:w-auto md:text-md"
        :disabled="disableOrderContractButton"
        data-test-id="change-order-contract-button"
        label="Change Contract"
        @click="toggleOrderContractFields"
      />
    </div>
    <div
      v-else-if="isEditingOrderContract && hasOrdersAdminPermission"
      class="flex"
    >
      <BaseButton
        class="w-full text-sm h-10 mt-2 md:w-auto md:text-md"
        data-test-id="cancel-order-contract-button"
        label="Cancel"
        @click="toggleOrderContractFields"
        outlined
      />
      <ButtonWithSpinner
        class="w-full text-sm h-10 mt-2 md:w-auto md:text-md"
        type="submit"
        data-test-id="save-order-contract-button"
        variant="secondary"
        variant-type="normal"
        :prop-loading="isSavingContract"
        @click="saveOrderContract"
      >
        <span>
          Save
        </span>
      </ButtonWithSpinner>
    </div>
  </div>

  <div class="overflow-x-auto md:overflow-x-hidden">
    <table v-if="!isLoadingHousing && table" class="m-4 p-2 w-full">
      <thead>
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
        >
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            class="py-4 pr-3 first:pl-2 text-left text-sm items-center text-gray-900"
          >
            <div class="flex items-center">
              {{ header.column.columnDef.header }}
            </div>
          </th>
        </tr>
      </thead>

      <tbody class="bg-white">
        <ExpandableItem
          v-if="sessionId"
          label="Academic: Study Session"
          :row="sessionRow"
          :expand-enabled="false"
          :edit-enabled="false"
        />

        <ExpandableItem
          v-if="classesFeesIndex >= 0"
          label="Academics: Classes"
          :row="classesFeesRow"
          :expand-enabled="false"
          :status-options="orderProductStatusOptions"
          :edit-enabled="canEditOrderItem"
          @open-status-modal="
            (id) => openClassesFeeModal(id, 'misc_products', 'misc_product')
          "
        />

        <ExpandableRows
          v-if="internshipIds.length"
          label="Academic: Internships"
          :rows="internshipRows"
          :loading="isLoadingOrderInternships"
          :edit-enabled="false"
          @expanded="loadInternships"
        />

        <ExpandableItem
          label="Housing"
          :row="housingRow"
          :data="normalizedHousingQuestionnaire"
          :loading="isLoadingHousingQuestionnaire || isLoadingProfile"
          :edit-enabled="canEditOrderItem"
          @open-status-modal="() => (housingModalIsOpen = true)"
          @expanded="loadHousingQuestionnaire"
        />

        <ExpandableRows
          label="Academic: Excursions"
          :rows="eventRows"
          :loading="isLoadingOrderEventOccurrences"
          :edit-enabled="canEditOrderItem"
          @expanded="loadOccurrences"
          @open-status-modal="openExcursionModal"
        />

        <ExpandableItem
          v-if="medicalInsuranceIndex >= 0"
          label="Insurance"
          :row="insuranceRow"
          :expand-enabled="false"
          :status-options="orderProductStatusOptions"
          :edit-enabled="canEditOrderItem"
          @open-status-modal="
            (id) => openStatusModal(id, 'misc_products', 'misc_product', false)
          "
        />

        <ExpandableRows
          :rows="addonsRows"
          label="Add Ons"
          :edit-enabled="canEditOrderItem"
          @open-status-modal="
            (id) => {
              openStatusModal(id, 'misc_products', 'misc_product');
            }
          "
        />
      </tbody>
    </table>
    <Spinner v-else class="p-2 relative bg-blue-100" />
  </div>
</template>
