<script setup>
import { computed, defineProps, ref, toRefs, watch, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import {
  useGetClasses,
  useGetHostInstitutionLanguages,
} from "@/components/program-manager/sessions/composable.js";
import { SCHOLARSHIP_LINK } from "@/constants";
import { useStore } from "vuex";
import getApplicableContract from "@/composables/useBillings";
import { formattedPriceFromCentsWithoutCents as formattedPrice } from "@/util/formatter";
import { convertClassFilterCriteria } from "@/components/program-manager/sessions/utils";
import {
  constructFilterCriteria,
  updateConjunctionRules,
} from "@/components/ExperiencePage/utils";
import { ERROR_TIMEOUT } from "@/constants.js";
import SkeletonLoader from "@/components/shared/loaders/BaseSkeletonLoader.vue";
import AdmissionDifficultyPopOver from "@/components/shared/Badge/RibbonDetails/AdmissionDifficultyPopOver.vue"
import { useDynamicHeight } from "@/composables/useDynamicHeights.js";

const props = defineProps({
  session: {
    type: Object,
    default() {
      return {};
    },
  },
  isLoading: {
    type: Boolean,
    default: true,
  },
});

const { session } = toRefs(props);
const toast = useToast();
const ribbonItems = ref({});
const hostInstitution = ref(undefined);

const state = reactive({
  toggleEmpty: false,
  showPage: false,
  isWrapperCourse: false,
  link_text: "",
  host_institution_url_syllabus: "",
  requiredCourses: [],
  electiveCourses: [],
  trackCourses: [],
});

const hasPrice = computed(() => {
  return session?.value?.base_price_in_cents;
});

const store = useStore();
const billingRules = computed(() => store.state.billingRules);

const hidePrice = computed(() => {
  if (!hasPrice.value) {
    return false;
  }

  const contracts = billingRules.value?.agreements ?? [];
  if (contracts.length < 1) {
    return false;
  }

  const applicableContract = getApplicableContract(contracts, session.value);

  return (
    Boolean(applicableContract) &&
    applicableContract?.direct_billing_type !== "Pass through"
  );
});

const isStudyAbroad = computed(() => {
  if (session?.value?.session_types) {
    return (
      session?.value?.session_types.filter((type) => type.name === "Study")
        .length > 0
    );
  }
  return null;
});

const isInternship = computed(() => {
  if (session?.value?.session_types) {
    return (
      session?.value?.session_types.filter((type) => type.name === "Internship")
        .length > 0
    );
  }
  return null;
});

const studyAndInternship = computed(() => {
  return isStudyAbroad.value && isInternship.value;
});

const difficultyLevel = computed(() => {
  let difficultyTextImage = { levelText: "", levelIcon: "" };
  switch (session.value.difficulty_score) {
    case 1:
      difficultyTextImage.levelText = "Level 1";
      difficultyTextImage.levelIcon = "/images/circular-gauge-icon-1.svg";
      return difficultyTextImage;
    case 2:
      difficultyTextImage.levelText = "Level 2";
      difficultyTextImage.levelIcon = "/images/circular-gauge-icon-2.svg";
      return difficultyTextImage;
    case 3:
      difficultyTextImage.levelText = "Level 3";
      difficultyTextImage.levelIcon = "/images/circular-gauge-icon-3.svg";
      return difficultyTextImage;
    case 4:
      difficultyTextImage.levelText = "Level 4";
      difficultyTextImage.levelIcon = "/images/circular-gauge-icon-4.svg";
      return difficultyTextImage;
    default:
      return null;
  }
});

const {
  execute: executeFetchHostInstitutionLanguagesOptions,
  state: languageData,
} = useGetHostInstitutionLanguages(undefined, false);

const languages = computed(() => {
  return languageData.value?.count > 0
    ? languageData?.value?.items
        ?.filter((language) =>
          session?.value?.class_filter_criteria?.required_languages
            .map((language) => language.id)
            .includes(language.id)
        )
        .map((language) => language.value)
    : undefined;
});

const filteredRibbon = computed(() => {
  return Object.values(ribbonItems.value).filter((ribbon) => ribbon.show);
});

const internshipFilteredRibbon = computed(() => {
  return Object.values(ribbonItems.value).filter(
    (ribbon) => ribbon.isInternship && ribbon.show
  );
});

const filteredStudyAndInternshipRibbon = computed(() => {
  return Object.values(ribbonItems.value).filter(
    (ribbon) => ribbon.studyAndInternship && ribbon.show
  );
});

const filters = ref({});

const classesParams = ref(convertClassFilterCriteria(filters));
const conjunctionRules = ref(undefined);
const notConjunction = ref([]);
const andOrConjunctionRules = ref(undefined);
const anyClassFromLibrary = ref(false);
const anyClassFromList = ref(false);

const { execute: fetchClassesData, state: classesData } = useGetClasses(
  { immediate: false, throwError: true, resetOnExecute: false },
  { ...classesParams.value, limit: 10, skip: 0 }
);

const getConjunctionIds = (conjunction, conjunctionRules) => {
  return conjunctionRules
    ?.filter((rule) => rule.conjunction === conjunction)
    .flatMap((rule) => rule.ids);
};

const fetchClassesDataWithFilters = async (filters) => {
  try {
    await fetchClassesData(0, filters);
    updateStateWithClassesData();
  } catch (e) {
    toast.open({
      message: "Unable to get course data.",
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  }
};

const updateStateWithClassesData = () => {
  state.toggleEmpty = !classesData?.value?.count;

  const peers = Array.from(
    new Set(
      classesData?.value?.data
        .map((item) => item.classroom_peers)
        .filter((value, index, self) => self.indexOf(value) === index)
        .flat()
    )
  );

  ribbonItems.value.peer.subText =
    peers.length > 2
      ? peers.map((peer) => peer).join(", ")
      : peers.map((peer) => peer).join(" & ");

  const [data] = classesData.value.data;
  if (data?.host_institution_url_syllabus && data?.link_text) {
    state.host_institution_url_syllabus = data.host_institution_url_syllabus;
    state.link_text = data.link_text;
    state.isWrapperCourse = true;
  }
};

watch(session, async (newSession) => {
  const size = session?.value?.forecasted_enrollments;

  let programSize = undefined;

  if (size > 0) {
    programSize = {
      sizeText: "Small",
      sizeSubText: "1-15 participants",
      sizeIcon: "/images/size-small.svg",
    };

    if (size > 15 && size < 50) {
      programSize.sizeText = "Medium";
      programSize.sizeSubText = "15-50 participants";
      programSize.sizeIcon = "/images/size-medium.svg";
    } else if (size >= 50) {
      programSize.sizeText = "Large";
      programSize.sizeSubText = "50+ participants";
      programSize.sizeIcon = "/images/size-large.svg";
    }
  }

  ribbonItems.value = {
    difficulty: {
      index: 1,
      forDifficulty: true,
      show: difficultyLevel.value !== null,
      studyAndInternship: studyAndInternship.value,
      text: "Admissions",
      subText: "difficulty",
      level: difficultyLevel?.value?.levelText ?? "",
      icon: difficultyLevel?.value?.levelIcon ?? "",
    },
    internshipDifficulty: {
      index: 2,
      show:
        isInternship.value && !studyAndInternship.value && !isStudyAbroad.value,
      isInternship: isInternship.value,
      text: "Competitive",
      icon: difficultyLevel?.value?.levelIcon,
    },
    stemSOC: {
      index: 3,
      show: false, // Need to revisit when ProgramPage.Session has soc_codes
      isInternship: isInternship.value,
      text: "Includes STEM Placements",
    },
    size: {
      index: 4,
      forSize: true,
      show: !!programSize,
      studyAndInternship: studyAndInternship.value,
      text: "Program size",
      subText: programSize?.sizeSubText ?? "",
      level: programSize?.sizeText,
      icon: programSize?.sizeIcon,
    },
    peer: {
      index: 5,
      show: isStudyAbroad,
      studyAndInternship: studyAndInternship.value,
      text: "Classroom peers",
      subText: "",
      icon: "/images/peer-icon.svg",
    },
    price: {
      index: 6,
      show: !hidePrice.value,
      isInternship: isInternship.value,
      studyAndInternship: studyAndInternship.value,
      text: `Starting from ${formattedPrice(
        session?.value?.base_price_in_cents
      )}`,
      subText: "See scholarship opportunities",
      url: "https://apiabroad.com/scholarships/",
      icon: "/images/piggy-bank-icon.svg",
    },
    environment: {
      index: 7,
      show: session?.value?.tags?.name === "Environmental Sustainability",
      studyAndInternship: studyAndInternship.value,
      isInternship: isInternship.value,
      text: "Environmental Sustainability",
    },
  };

  if (!newSession) return;

  const sessionClassSelectionRules = newSession.session_class_selection_rules;

  if (sessionClassSelectionRules) {
    const {
      conjunctionRules: newConjunctionRules,
      notConjunction: newNotConjunction,
      andOrConjunctionRules: newAndOrConjunctionRules,
      anyClassFromLibrary: newAnyClassFromLibrary,
      anyClassFromList: newAnyClassFromList,
    } = updateConjunctionRules(sessionClassSelectionRules, getConjunctionIds);
    conjunctionRules.value = newConjunctionRules;
    notConjunction.value = newNotConjunction;
    andOrConjunctionRules.value = newAndOrConjunctionRules;
    anyClassFromLibrary.value = newAnyClassFromLibrary;
    anyClassFromList.value = newAnyClassFromList;
  }

  if (newSession?.class_filter_criteria) {
    hostInstitution.value =
      newSession?.class_filter_criteria?.host_institutions?.[0];

    if (hostInstitution?.value?.id) {
      try {
        await executeFetchHostInstitutionLanguagesOptions(
          0,
          hostInstitution?.value?.id
        );
      } catch (exception) {
        toast.open({
          message: "Was not able to load Institution data.",
          type: "error",
          position: "bottom",
          duration: ERROR_TIMEOUT,
        });
      }
    }

    const newFilters = constructFilterCriteria(
      newSession,
      notConjunction.value
    );
    filters.value = newFilters;
    await fetchClassesDataWithFilters(newFilters);
  }
});
const target = ref(null);
const wrapper = ref(null);
const { inViewHeight } = useDynamicHeight(target, wrapper);

</script>
<template>
  <div ref="wrapper" :style="{ height: inViewHeight }" class="transition-height overflow-y-hidden md:overflow-y-visible bg-gray-10">
  <div ref="target"
    class="w-full md:min-h-[200px]"
    :class="[secondaryColor ? `bg-gray-275` : `bg-university-accentSecondary`]">
    <SkeletonLoader v-if="isLoading" :from-color="'#D5E9E7'" :to-color="'#F9FAFB'" :height="'h-48'" class="mt-4"
      rounded />
    <div v-else class="mx-auto max-w-7xl">
      <div v-if="!isInternship && filteredRibbon?.length > 1" class="grid grid-cols-1 gap-px sm:grid-cols-2"
        :class="(`lg:grid-cols-${filteredRibbon?.length}`)">
        <div v-for="ribbon in filteredRibbon" :key="ribbon.index" class="px-4 py-6 sm:px-6 lg:px-8 text-center">
          <div class="text-center" :class="[ribbon.forSize ? 'mb-4 mt-4' : 'mb-2']">
            <img class="mx-auto" :class="[!ribbon.level ? 'h-16 w-16' : '']"
              :src="ribbon.icon ?? '/images/api-card-logo.png'" alt="admissions difficulty" />
            <p v-if="ribbon.level" class="text-base font-bold uppercase text-indigo-base mt-0">
              {{ ribbon.level }}
            </p>
          </div>
          <p class="text-lg font-bold uppercase text-indigo-base">
            {{ ribbon.text }}
          </p>
          <p v-if="ribbon.url">
            <a target="_blank" :href="SCHOLARSHIP_LINK" class="underline" @click.stop>
              {{ ribbon.subText }}</a>
          </p>
          <p v-else class="text-lg text-indigo-base"
            :class="[ribbon.forDifficulty ? 'font-bold uppercase relative' : '']">
            {{ ribbon.subText }}
            <AdmissionDifficultyPopOver v-if="ribbon.forDifficulty" :container-id="'study-abroad-container'"
              :icon-id="'study-abroad-icon'" :content-template-id="'study-abroad-content'" />
          </p>
        </div>
      </div>
      <div v-if="
          isInternship &&
          !studyAndInternship &&
          internshipFilteredRibbon.length > 1
        " class="grid grid-cols-1 gap-px sm:grid-cols-2" :class="`lg:grid-cols-[${filteredRibbon?.length}]`">
        <div v-for="ribbon in internshipFilteredRibbon" :key="ribbon.index"
          class="px-4 py-6 sm:px-6 lg:px-8 text-center">
          <div class="text-center mb-2">
            <img class="mx-auto h-10 w-10" :src="ribbon.icon ?? '/images/api-card-logo.png'"
              alt="admissions difficulty" />
            <label v-if="ribbon.level" class="text-base font-bold uppercase text-indigo-base">
              {{ ribbon.level }}
            </label>
          </div>
          <p class="text-lg font-bold uppercase text-indigo-base">
            {{ ribbon.text }}
          </p>
          <p class="text-lg text-indigo-base" :class="[ribbon.level ? 'font-bold uppercase' : '']">
            {{ ribbon.subText }}
          </p>
        </div>
      </div>
      <div v-if="
          studyAndInternship && filteredStudyAndInternshipRibbon?.length > 1
        " class="grid grid-cols-1 gap-px sm:grid-cols-2"
        :class="(`lg:grid-cols-${filteredStudyAndInternshipRibbon?.length}`)">
        <div v-for="ribbon in filteredStudyAndInternshipRibbon" :key="ribbon.index"
          class="px-4 py-6 sm:px-6 lg:px-8 text-center">
          <div class="text-center" :class="[ribbon.forSize ? 'mb-4 mt-4' : 'mb-2']">
            <img class="mx-auto" :class="[!ribbon.level ? 'h-16 w-16' : '']"
              :src="ribbon.icon ?? '/images/api-card-logo.png'" alt="admissions difficulty" />
            <p v-if="ribbon.level" class="text-base font-bold uppercase text-indigo-base mt-0">
              {{ ribbon.level }}
            </p>
          </div>
          <p class="text-lg font-bold uppercase text-indigo-base">
            {{ ribbon.text }}
          </p>
          <p v-if="ribbon.url">
            <a target="_blank" :href="SCHOLARSHIP_LINK" class="underline" @click.stop>
              {{ ribbon.subText }}</a>
          </p>
          <p v-else class="text-lg text-indigo-base"
            :class="[ribbon.forDifficulty ? 'font-bold uppercase relative' : '']">
            {{ ribbon.subText }}
            <AdmissionDifficultyPopOver v-if="ribbon.forDifficulty" :container-id="'mixed-container'"
              :icon-id="'mixed-icon'" :content-template-id="'mixed-content'" />
          </p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>