import programSessionsService from "@/services/programSessions";
import housingService from "@/services/housings";
import cipCodesService from "@/services/cipCodes";
import { useAsyncState } from "@vueuse/core";
import hostInstitutionService from "@/services/hostInstitution";
import citiesService from "@/services/cities";
import countriesService from "@/services/countries";
import entitiesService from "@/services/entities";
import internshipService from "@/services/internship";
import classesService from "@/services/classes";
import tagsService from "@/services/tags";
import profileService from "@/services/profile";
import programsPageService from "@/services/programsPage";
import workLocationsService from "@/services/workLocations";
import eventsService from "@/services/events";
import { debounce } from "lodash";
import { convertLatLongDDtoDMS } from "@/components/program-manager/sessions/utils.js";
import axios from "axios";
import { convertEventsToOccurrences } from "@/components/program-manager/sessions/components/ExcursionsAndEvents/utils";

export const useGetProgramSessionsOptions = (
  asyncStateOptions,
  startParams
) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();

  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return programSessionsService.getProgramSessions(params).then((val) => {
        if (params?.search || params?.programSessionId) {
          const items = val?.data?.data?.items?.filter(
            (val) =>
              val?.name?.includes(params?.search) &&
              val.id !== params?.programSessionId
          );
          return { count: items.length, items };
        }
        return val?.data?.data;
      });
    },
    { count: -1, items: [] },
    asyncStateOptions
  );
};

export const getSingleProgramSession = (id, immediate = true) =>
  useAsyncState(
    (extraParams = {}) =>
      programSessionsService
        .getProgramSession({ id, ...extraParams })
        .then((val) => {
          return val?.data?.data?.items;
        }),
    {},
    { immediate }
  );

export const useGetHostInstitutionOptions = (immediate = true) =>
  useAsyncState(
    ({ search = "", page = 1, limit = 25, extraParams = {}, source } = {}) =>
      hostInstitutionService
        .getHostInstitutions({
          q: search,
          page,
          limit,
          extraParams,
          source,
        })
        .then((val) => {
          return val?.data.data;
        }),
    { count: -1, data: [] },
    { immediate }
  );

export const useGetSingleHostInstitution = (id, immediate = true) =>
  useAsyncState(
    () =>
      hostInstitutionService.getHostInstitutionById(id).then((val) => {
        return val?.data?.data || {};
      }),
    [],
    { immediate }
  );

export const useGetEntitySupplement = (defaultId, immediate = true) =>
  useAsyncState(
    (id) =>
      hostInstitutionService
        .getEntitySupplementById(defaultId || id)
        .then((val) => val?.data?.data || {}),
    [],
    { immediate }
  );

export const useGetHostInstitutionLanguages = (defaultId, immediate = true) =>
  useAsyncState(
    (id) =>
      hostInstitutionService
        .getHostInstitutionLanguageById(defaultId || id)
        .then((val) => val?.data?.data || {}),
    [],
    { immediate }
  );

export const useGetHomeInstitutionOptions = (immediate = true) =>
  useAsyncState(
    ({ search = "", page = 1, limit = 25, extraParams = {}, source } = {}) =>
      housingService
        .getHomeInstitutions({ q: search, page, limit, extraParams, source })
        .then((val) => {
          return val?.data?.data?.items || [];
        }),
    [],
    { immediate }
  );

export const useGetSingleHomeInstitution = (ids, immediate = true) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ids: propsIds } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();

      return housingService
        .getHomeInstitutionsById(ids ?? propsIds)
        .then((val) => val?.[0]?.data?.data?.items);
    },
    [],
    { immediate },
  );
};

export const useGetRequiredMajorOptions = (immediate = true) =>
  useAsyncState(
    ({ search = "", page = 1, limit = 25, extraParams = {}, source } = {}) =>
      cipCodesService
        .listObj({ q: search, page, limit, extraParams, source })
        .then((val) => {
          return (
            val.map((ele) => ({
              ...ele,
              id: ele.cip_code,
              name: ele.cip_title,
            })) || []
          );
        }),
    [],
    { immediate }
  );

export const useGetCityOptions = (
  asyncStateOptions,
  startParams,
  filterNames
) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel("cancel");
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return citiesService.getCities(params).then((val) => {
        const items = val?.data?.data?.items || [];
        const count = val?.data?.data?.count;
        if (filterNames) {
          const mapped = items.reduce((acc, val) => {
            acc[val.city_ascii] = {
              city_ascii: val.city_ascii,
              id: val.city_ascii,
              city_id: val.id,
              country: val?.country || {},
              lat_lng: convertLatLongDDtoDMS(val?.lat_lng || ""),
              admin_name: val?.admin_name || "",
              admin_name_ascii: val?.admin_name_ascii || "",
            };
            return acc;
          }, {});
          return { items: Object.values(mapped) || [], count };
        } else {
          return { items, count };
        }
      });
    },
    [],
    asyncStateOptions
  );
};

export const useGetSingleCity = ({ city_id }, immediate = true) =>
  useAsyncState(
    () =>
      citiesService.getSingleCity({ city_id }).then((val) => {
        return val?.data?.data || [];
      }),
    [],
    { immediate }
  );

export const useGetSingleProgram = (id, immediate = true) =>
  useAsyncState(
    () =>
      programsPageService
        .getProgramPage(id)
        .then((val) => {
          return val?.data?.data || {};
        }),
    {},
    { immediate, throwError: true }
  );

export const useGetPrograms = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();

  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return programsPageService
        .getProgramPages(params)
        .then((val) => val?.data?.data);
    },
    { count: -1, items: [] },
    asyncStateOptions
  );
};

export const useSearchCountryOptions = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel("cancel");
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return countriesService.searchCountries(params).then((val) => {
        return val?.data?.data || [];
      });
    },
    [],
    asyncStateOptions
  );
};

export const useGetTermsOptions = (immediate = true) =>
  useAsyncState(
    () =>
      housingService.getTerms().then((val) => {
        return val?.data?.data || [];
      }),
    [],
    { immediate }
  );

export const useGetTagsOptions = (immediate = true, categories = []) =>
  useAsyncState(
    (categoriesOverride = []) =>
      tagsService.getTagsOrderByCategory().then((val) => {
        const data = val?.data?.data?.items || [];
        const tags = [];
        data.forEach((category) => {
          if (
            category?.tags &&
            (categories.find((val) => val === category.name) ||
              categoriesOverride.find((val) => val === category.name))
          ) {
            tags.push({ id: undefined, name: category.name, category: true });
            category.tags.forEach((tag) => {
              tags.push({ id: tag.id, name: tag.name, category: false });
            });
          }
        });
        return tags;
      }),
    [],
    { immediate }
  );

export const useGetProfiles = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return profileService.getProfiles(params).then((profiles) => {
        return profiles?.data || {};
      });
    },
    { count: -1, items: [] },
    asyncStateOptions
  );
};

export const useGetSingleProfile = (id, immediate = true) =>
  useAsyncState(
    (props) =>
      profileService.getProfile(id ?? props?.id).then((profile) => {
        return profile?.data || {};
      }),
    {},
    { immediate },
  );

export const useGetLimitedProfiles = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return profileService.getLimitedProfiles(params).then((profiles) => {
        return profiles?.data || {};
      });
    },
    { count: -1, items: [] },
    asyncStateOptions
  );
};

export const useGetInternships = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return internshipService.getInternships(params).then((response) => {
        return response.data;
      });
    },
    { data: { count: -1, items: [] } },
    asyncStateOptions
  );
};

export const useGetClasses = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      if (params?.cip_code?.length > 1) {
        params.cip_code = params.cip_code.join(",");
      }
      return classesService.getClasses(params).then((response) => {
        return response.data;
      });
    },
    { count: -1, data: [] },
    asyncStateOptions
  );
};

export const useGetEntities = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return entitiesService.getEntities(params).then((response) => {
        return response.data.data;
      });
    },
    { count: -1, items: [] },
    asyncStateOptions
  );
};

export const debounceSearchWrapper = (fetchFunc, waitTime, funcArgs) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return debounce(async (search, loading) => {
    source.cancel("cancel");
    cancelToken = axios.CancelToken;
    source = cancelToken.source();
    if (search) {
      loading(true);
      try {
        const delay = funcArgs?.delay || 0;
        await fetchFunc(delay, { ...funcArgs, search, source });
      } catch (e) {
        if (e?.message !== "cancel") {
          loading(false);
        }
      }
    }
  }, waitTime);
};

export const useGetHousings = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = {
        ...startParams,
        ...callParams,
        source,
        failureMessage: "canceled",
      };
      return housingService.getHousings(params).then((response) => {
        return response.data;
      });
    },
    { data: [] },
    asyncStateOptions
  );
};

export const useGetEvents = (
  asyncStateOptions,
  startParams,
  occurrenceConvert = true
) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = {
        ...startParams,
        ...callParams,
        source,
        failureMessage: "canceled",
      };
      return eventsService.listProxy(params).then((response) => {
        if (occurrenceConvert) {
          return convertEventsToOccurrences(response.data?.data?.items || []);
        } else {
          return response.data;
        }
      });
    },
    [],
    asyncStateOptions
  );
};

export const useGetWorkLocations = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return workLocationsService.getWorkLocations(params).then((response) => {
        const data = response?.data?.data;
        return {
          count: data?.count || 0,
          data: data?.items || [],
        };
      });
    },
    { count: -1, data: [] },
    asyncStateOptions
  );
};

export const useGetOccurrences = (asyncStateOptions, startParams) => {
  let cancelToken = axios.CancelToken;
  let source = cancelToken.source();
  return useAsyncState(
    (props) => {
      const { cancel, ...callParams } = props || {};
      if (cancel) {
        source.cancel();
      }
      cancelToken = axios.CancelToken;
      source = cancelToken.source();
      const params = { ...startParams, ...callParams, source };
      return eventsService.getOccurrences(params).then((response) => {
        return {
          data: response?.data?.data?.items || [],
          count: response?.data?.data?.count || 0,
        };
      });
    },
    { count: -1, data: [] },
    asyncStateOptions
  );
};

export const useUpdateCityRecord = ({ city_id }, immediate = false) =>
  useAsyncState(
    ({ payload }) =>
      citiesService
        .updateCity({
          city_id,
          payload,
        })
        .then((val) => {
          return val?.data?.data || [];
        }),
    [],
    { immediate }
  );

export const useAcademicYearFilter = (param = {}) => {
  const options = [];

  const nowYear = new Date().getFullYear();
  for (let i = -10; i < 10; i++) {
    options.push({
      id: param.idIsNotIndex ? `${nowYear + i}-${nowYear + i + 1}` : i,
      name: `${nowYear + i}-${nowYear + i + 1}`,
    });
  }

  const reduceAcademicYearOption = (option) => option.name.split("-")[0];

  return {
    options,
    reduceAcademicYearOption,
  };
};

export const debouncedFetchCityOptions = (fetchFunc, waitTime) =>
  debounce(async (search, loading) => {
    if (search && search?.length > 2) {
      loading(true);
      try {
        await fetchFunc(0, { city_ascii: search, cancel: true });
        loading(false);
      } catch (e) {
        if (e?.message !== "cancel") {
          loading(false);
        }
      }
    }
  }, waitTime);
