<script>
export default {
  name: "OrderDetail",
};
</script>

<script setup>
import { reactive, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";

import { ERROR_TIMEOUT, SUCCESS_TIMEOUT } from "@/constants";
import { ERROR_MESSAGE } from "@/components/StudentFulfillment/components/modals/HousingFulfillmentModal/constants";
import BreadCrumb from "@/components/shared/BreadCrumb";
import ExpandingSection from "@/components/shared/section/ExpandingSection";
import StudentInfo from "@/components/StudentFulfillment/components/OrderDetail/sections/StudentInfo";
import DetailSection from "@/components/StudentFulfillment/components/OrderDetail/sections/Details/index";
import InvoiceSection from "@/components/StudentFulfillment/components/OrderDetail/sections/Invoice/index";
import Spinner from "@/components/helpers/Spinner.vue";
import BaseButton from "@/components/shared/Button/BaseButton";
import ErrorPage from "@/components/errorPage.vue";
import NotesSection from "@/components/StudentFulfillment/components/OrderDetail/sections/Notes/index";
import PlusIcon from "@/components/shared/icons/PlusIcon";
import PriceDifferenceModal from "@/components/StudentFulfillment/components/modals/PriceDifferenceModal";
import { useGetProgramSession } from "@/components/StudentFulfillment/composables/sessions";
import { useGetOrderSessionDiff } from "@/composables/useOrder.js";
import { ORDER_STATUS } from "@/constants.js";
import { useGetSingleOrder } from "@/components/StudentFulfillment/composables/orders";
import ordersService from "@/services/orders";
import profileService from "@/services/profile";
import { useToast } from "vue-toast-notification";
import {
  isAuthorized,
  hasOrdersWritePermission,
  hasUsersAdvisePermission,
  hasInvoiceFeatureEnabled,
} from "@/composables/authorization";
import { PERMISSIONS } from "@/constants";

const route = useRoute();
const toast = useToast();
const updateData = reactive({
  needs_advising: undefined,
  financially_cleared: undefined,
  requires_accommodations: undefined,
  financial_aid: [
    {
      using_financial_aid: undefined,
      certified_fafsa_eligible: undefined,
      eligible_for_member_scholarships: undefined,
    },
  ],
});
const disableButton = ref(false);
const openAddEditNoteModal = ref(false);
const openPriceDiffModal = ref(false);
const ordersDiff = ref({});
const hideButton = computed(() => {
  return !isAuthorized([PERMISSIONS.USERS_ADVISE, PERMISSIONS.ORDERS_WRITE]);
});

const {
  isLoading: orderLoading,
  state: orderData,
  error: orderError,
} = useGetSingleOrder({
  order_id: route.params?.orderId,
  get_canceled: true,
  get_withdrawn: true,
});

const updateOrder = async (payload) => {
  await ordersService.updateOrder({
    order_id: route.params?.orderId,
    payload: { ...orderData.value.order, ...payload },
    callingLocation: "OrderDetails",
  });
};

const updateProfile = async ({ id, ...payload }) => {
  await profileService.updatePartiallyProfile(id, payload);
};

const submitChanges = async () => {
  const {
    financially_cleared = undefined,
    needs_advising = undefined,
    requires_accommodations = undefined,
  } = updateData;

  openPriceDiffModal.value = false;
  disableButton.value = true;
  orderLoading.value = true;

  try {
    await updateOrder({
      financially_cleared,
      update_invoice:
        hasInvoiceFeatureEnabled.value &&
        orderData.value.order.status == ORDER_STATUS.fulfilled,
    });

    await updateProfile({
      needs_advising,
      requires_accommodations,
      id: orderData.value.order?.participant_id,
    });

    toast.open({
      message: "Order Saved Successfully",
      type: "success",
      position: "bottom",
      duration: SUCCESS_TIMEOUT,
    });
  } catch (error) {
    const errorMessage = error.cause?.data?.details?.detail ?? ERROR_MESSAGE;

    toast.open({
      message: errorMessage,
      type: "error",
      position: "bottom",
      duration: ERROR_TIMEOUT,
    });
  } finally {
    disableButton.value = false;
    orderLoading.value = false;
  }
};
const handleClosePriceDiffModal = () => {
  openPriceDiffModal.value = false;
};
const handleSaveButton = async () => {
  openPriceDiffModal.value = true;
  await loadOrderPricingDiff();
};

const loadOrderPricingDiff = async () => {
  if (orderData?.value?.order) {
    const sessionId = orderData?.value?.order?.session_id;
    if (sessionId && !programSession?.value?.items) {
      await executeFetchProgramSession(0, sessionId);
    }
    ordersDiff.value =
      (await useGetOrderSessionDiff({
        programSession: programSession?.value?.items,
        order: orderData?.value?.order,
        newProducts: [],
      })) || {};
  }
};

const {
  isReady: isProgramSessionReady,
  execute: executeFetchProgramSession,
  state: programSession,
} = useGetProgramSession({ immediate: false, throwError: true });

watch(
  () => orderData.value,
  () => {
    if (orderData?.value?.order?.status !== ORDER_STATUS.withdrawn) {
      loadOrderPricingDiff();
    }
  }
);
</script>

<template>
  <div class="bg-blue-100 text-sm text-indigo-base">
    <PriceDifferenceModal
      :is-modal-open="openPriceDiffModal"
      :orders-diff="ordersDiff"
      @go-on="submitChanges"
      @close-modal="handleClosePriceDiffModal"
    ></PriceDifferenceModal>
    <div v-if="!orderError && !orderLoading && orderData" class="mx-5">
      <div class="flex items-baseline justify-between">
        <BreadCrumb class="mt-10" />
      </div>
      <ExpandingSection
        field-label="Student Info"
        :input-classes="['!bg-white mt-3 mb-3']"
        :unmount="false"
        class="col-span-2"
      >
        <template #button
          ><BaseButton
            v-if="!orderLoading && !hideButton"
            class="h-10 font-bold"
            :disabled="disableButton"
            data-test-id="save-student-info-button"
            @click="handleSaveButton"
            >Save</BaseButton
          ></template
        >
        <template #content>
          <StudentInfo
            v-model="updateData"
            :order-loading="orderLoading"
            :data="orderData"
          />
        </template>
      </ExpandingSection>

      <ExpandingSection
        field-label="Invoice details"
        :input-classes="['!p-0 !border-white !bg-white mt-3 mb-3']"
        :unmount="false"
        class="col-span-2"
      >
        <template #content>
          <InvoiceSection
            :order-id="orderData?.order?.id"
            :application-id="orderData?.order?.application_id"
          />
        </template>
      </ExpandingSection>

      <ExpandingSection
        field-label="Order details"
        :input-classes="['!p-0 !border-white !bg-white mt-3 mb-3']"
        :unmount="false"
        class="col-span-2"
      >
        <template #content>
          <DetailSection
            v-model="orderData"
            :program-session="programSession"
          />
        </template>
      </ExpandingSection>

      <ExpandingSection
        field-label="Notes"
        :input-classes="['!p-0 !border-white !bg-white mt-3 mb-3']"
        :unmount="false"
        class="col-span-2"
      >
        <template #button>
          <button
            class="flex justify-center items-center ml-4 text-sm font-bold bg-white text-teal-900 uppercase"
            v-if="hasOrdersWritePermission || hasUsersAdvisePermission"
            @click="() => (openAddEditNoteModal = true)"
          >
            <PlusIcon /><span class="ml-2">New Note</span>
          </button>
        </template>
        <template #content>
          <NotesSection
            :order-data="orderData"
            :open-add-edit-note-modal="openAddEditNoteModal"
            @close-modal-add-edit-note-modal="
              () => (openAddEditNoteModal = false)
            "
          />
        </template>
      </ExpandingSection>
    </div>
    <ErrorPage
      v-else-if="!orderLoading && orderError"
      class="relative h-65vh"
      message="Failed to load order detail data"
      disable-code
    />
    <Spinner v-else class="relative h-screen bg-blue-100" />
  </div>
</template>
