import { algoliaConfigurations } from "@/components/ExperiencePage/Algolia/configurations";
import { featureFlags } from "@/composables/authorization.js";

export const TAB_ROUTE_NAMES = {
  INTERNSHIP_POSTINGS: "internship-postings-results-views",
  PROGRAMS: "programs-results-view",
};

export const TAB_NAMES = {
  PROGRAMS: "Programs",
  INTERNSHIP_POSTINGS: "Internship postings",
  COURSES: "Courses",
};
const indexPreface = featureFlags.value["algolia-index-override"]
  ? featureFlags.value["algolia-index-override"]
  : process.env.MIX_APP_ENV === "production"
  ? "production"
  : "staging";

export const TABS = [
  {
    name: TAB_NAMES.PROGRAMS,
    component: "ProgramsTab",
    slug: "/results/programs",
    value: "programs",
    algoliaIndex: `${indexPreface}_search_sessions`,
  },
  {
    name: TAB_NAMES.INTERNSHIP_POSTINGS,
    component: "InternshipTab",
    slug: "/results/internship-postings",
    value: "internships",
    algoliaIndex: `${indexPreface}_search_internships`,
  },
  {
    name: TAB_NAMES.COURSES,
    component: "CoursesTab",
    slug: "/results/courses",
    value: "classes",
    algoliaIndex: `${indexPreface}_search_classes`,
  },
];

export const COURSE_ROUTE = "/results/courses";
export const INTERNSHIP_ROUTE = "/results/internship-postings";

export const algoliaConfigurationMap = {
  programs: algoliaConfigurations.session,
  internships: algoliaConfigurations.internship,
  classes: algoliaConfigurations.classes,
};

export const DEFAULT_IMAGE = "/images/api-card-logo.png";

export const HITS_PER_PAGE = 12;

// These filter we'll add to the algolia pre ui state, these configurations use algolia components so
// we need to do this if we want to pre set their values from the URL query params
export const ALGOLIA_PRESTATE_ADD_FILTER_NAMES = [
  "countries",
  "cities",
  "timeframe",
  "language",
  "hostInstitution",
  "courseLevel",
  "programTypes",
  "sessionId",
];

export const countriesTranslationMap = {
  "United Kingdom": "United Kingdom (England and Scotland)",
};

export const countriesTranslationBackMap = Object.keys(
  countriesTranslationMap
).reduce((acc, key) => {
  acc[countriesTranslationMap[key]] = key;
  return acc;
}, {});
