import { ERROR_TIMEOUT } from "@/constants";
import { formattedPriceFromCentsWithoutCents } from "@/util/formatter";
import { unref } from "vue";
import { format, parseISO, getTime, addHours } from "date-fns";

// Formats a value for use in an Algolia query
export const formatValueForAlgolia = (val) =>
  /\s|,/.test(val) ? `'${val}'` : val;

// Handles range filters for Algolia queries
export const handleRangeFilter = (facetName, range) => {
  return [`${facetName}:${range[0]} TO ${range[1]}`];
};

export const handlePastProgramsFilter = (attribute, value) => {
  if (!value) {
    // Format current date in local timezone
    const dateString = format(new Date(), "yyyy-MM-dd");

    // Parse the formatted date string as a local date
    const parsedDate = parseISO(dateString);

    // Adjust the parsed date to start of day UTC
    const utcDate = addHours(parsedDate, -parsedDate.getTimezoneOffset() / 60);

    // Get the timestamp of the UTC date
    const timestamp = getTime(utcDate);

    return [`${attribute} >= ${timestamp}`];
  }
};

// Handles standard filters for Algolia queries
export const handleStandardFilter = (facetName, value) => {
  const formattedValues = Array.isArray(value)
    ? value.map(formatValueForAlgolia)
    : [formatValueForAlgolia(value)];
  return formattedValues.map((val) => `${facetName}:${val}`);
};

export const fetchFacetOptions = async ({
  facetName,
  facetQuery = "",
  searchParameters = {},
  index,
  toast,
  maxFacetHits = 100,
}) => {
  if (facetName) {
    try {
      const result = await index.searchForFacetValues(facetName, facetQuery, {
        ...searchParameters,
        maxFacetHits,
      });
      const facets = result?.facetHits || [];
      const options = facets.map(({ value }) => ({
        key: value,
        label: value,
        value: value,
      }));

      return options;
    } catch (error) {
      toast.open({
        message: "Error while loading data. Please, try it later",
        type: "error",
        position: "bottom",
        duration: ERROR_TIMEOUT,
      });
    }
  }
};

export const internshipsConverter = (newItems, internshipsData) => {
  let retVal = [];
  // for comparison
  const existingIds = internshipsData.value.map((i) => i.internship_id);

  if (!newItems || newItems.length === 0) {
    retVal = [];
  } else {
    try {
      retVal = newItems.map((item) => {
        if (!existingIds.includes(item.internship.id)) {
          const { session, program_page, internship } = item;
          return {
            ...program_page,
            ...internship,
            id: program_page?.id, // need to do this because of the naming of the ProgramCard props interface
            internship_id: internship?.id,
            program_page_id: program_page?.id,
            program_page: {
              name: internship?.title || "",
            },
            project: internship?.placement_types?.[0]?.value ?? "",
            industry: internship?.industries?.[0]?.career_pathway ?? "",
            price: formattedPriceFromCentsWithoutCents(
              session?.base_price_in_cents
            ),
            weeks: internship?.duration ?? "",
            session_id: session?.id,
            session: session,
            program_id: program_page?.id,
            session_terms: session?.terms?.map((term) => ({
              id: term.id,
              year: session?.session_travel_details?.[0]?.arrival_date.split(
                "-"
              )[0],
            })),
            session_types: session?.session_types,
            session_travel_details: session?.session_travel_details,
            base_price_in_cents: session?.base_price_in_cents ?? 0,
          };
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
  return retVal;
};

const mapFilterKeyToAlgoliaFacet = ({ algoliaConfiguration, key, value }) => {
  let retVal;
  const configuration = unref(algoliaConfiguration);
  if (configuration[key]) {
    const facetName = configuration[key];
    switch (key) {
      case "areaOfStudy":
      case "career":
      case "cities":
      case "countries":
      case "courseLevel":
      case "hostInstitution":
      case "keyword":
      case "language":
      case "price":
      case "programLength":
      case "programTypes":
      case "timeframe":
        break;
      case "pastPrograms":
        retVal = [
          { facetName, value: handlePastProgramsFilter(facetName, value) },
        ];
        break;
      case "internshipStatus":
        retVal = [
          { facetName, value: handleStandardFilter(facetName, ["Active"]) },
        ];
        break;
      case "classActive":
        retVal = [
          { facetName, value: handleStandardFilter(facetName, ["Active"]) },
        ];
        break;
      case "activeSession":
        retVal = [
          { facetName, value: handleStandardFilter(facetName, ["true"]) },
        ];
        break;
      case "sessionStatusId":
        retVal = [
          { facetName, value: handleStandardFilter(facetName, [2, 6]) },
        ];
        break;
      case "programPageStatus":
        retVal = [{ facetName, value: handleStandardFilter(facetName, [6]) }];
        break;
      case "sessionId":
        if (value?.[1]) {
          retVal = [
            { facetName, value: handleStandardFilter(facetName, value[1]) },
          ];
        }
        break;
      default:
        retVal = [{ facetName, value: handleStandardFilter(facetName, value) }];
        break;
    }
  }
  return retVal;
};

export const buildFilterStrings2 = (algoliaConfiguration, clonedFilters) => {
  const groupedFilters = {};
  for (const [key, value] of Object.entries(algoliaConfiguration)) {
    const mapFilterKeysToAlgoliaFacets = mapFilterKeyToAlgoliaFacet({
      algoliaConfiguration,
      key,
      value: clonedFilters?.[key],
    });
    mapFilterKeysToAlgoliaFacets?.forEach(({ facetName, value } = {}) => {
      if (facetName && value) {
        groupedFilters[facetName] = value;
      }
    });
  }

  const facetFilterStrings = [];
  for (const [key, value] of Object.entries(groupedFilters)) {
    if (value.length > 1) {
      facetFilterStrings.push(`(${value.join(` OR `)})`);
    } else if (value?.[0]) {
      facetFilterStrings.push(value[0]);
    }
  }
  const andedFitlers = facetFilterStrings.join(" AND ");
  return andedFitlers;
};

export const reLabel = (item, translationMap = {}, label = "label") => {
  const originalLabel = item[label];
  const translation = translationMap[originalLabel];
  if (translation) {
    if (typeof translation === "string") {
      return translation;
    } else if (typeof translation === "function") {
      return translation(item);
    }
  }
  return originalLabel;
};
